import React, { useState } from "react";
import { Grid, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { AddCardIcon, AUBankIcon, AxisBankIcon, IDFCBankIcon, UnionBankIcon, YesBankIcon } from "../../res/icons";

const MyCards = () => {
    const userType = localStorage.getItem("uType");
    const [activeCardIndex, setActiveCardIndex] = useState(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const handleViewDetails = (index) => {
        setActiveCardIndex(index === activeCardIndex ? null : index);
    };

    const cardsData = [
        {
            bankName: "UNION BANK",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "583801010050602",
            ifsc: "UBIN0558389",
            branch: "Baner, Pune",
            icon: UnionBankIcon,
            color: "#D81F40",
        },
        {
            bankName: "AU SMALL Finance Bank",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "2402235358748585",
            ifsc: "AUBL0002353",
            branch: "Prabhat Road, Pune",
            icon: AUBankIcon,
            color: "#660F59",
        },
        {
            bankName: "AXIS Bank",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "919020076135843",
            ifsc: "UTIB0002754",
            branch: "Balewadi, Pune",
            icon: AxisBankIcon,
            color: "#721324",
        },
        {
            bankName: "IDFC Bank",
            company: "EDSOM FINTECH PVT.LTD.",
            accountNo: "10066469212",
            ifsc: "IDFB0041433",
            branch: "Balewadi, Pune",
            icon: IDFCBankIcon,
            color: "#991F35",
        },
    ];

    return (
        <Grid container style={{ marginTop: '3%' }} justifyContent="center">
            <Paper
                elevation={4}
                style={{
                    marginTop: "2%",
                    padding: "2%",
                    textAlign: "center",
                    backgroundColor: "#FFFFFF",
                    borderRadius: 20,
                    width: isMobile ? "95%" : "100%",
                    maxWidth: "1200px",
                    marginBottom: "4%",
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>
                        My Banks
                    </Typography>
                    {userType === "ADMIN" && (
                        <Typography style={{ cursor: 'pointer' }}>
                            <AddCardIcon />
                        </Typography>
                    )}
                </Grid>
                <Grid
                    container
                    spacing={isMobile ? 2 : isTablet ? 3 : 4}
                    direction={isMobile ? "column" : "row"}
                    justifyContent="center"
                    alignItems="center"
                >
                    {cardsData.map((card, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card
                                style={{
                                    backgroundColor: card.color,
                                    borderRadius: "20px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    padding: "10px",
                                    marginBottom: "16px",
                                    minHeight: "20px", // Fixed minimum height for all cards
                                    height: activeCardIndex === index ? "auto" : "200px", // Adjust height for active/inactive cards
                                    width: isMobile ? "100%" : isTablet ? "100%" : activeCardIndex === index ? "100%" : "60%",
                                    transition: 'width 0.9s ease-in-out, height 0.9s ease-in-out', // Smooth transition for both width and height
                                }}
                            >
                                <CardContent style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                    <Grid
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: isMobile ? "column" : "row",
                                        }}
                                    >
                                        <card.icon
                                            style={{
                                                marginRight: activeCardIndex === index ? '10px' : '0',
                                                color: '#FFFFFF',
                                                fontSize: isMobile ? '30px' : isTablet ? '35px' : '40px',
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: "#FFFFFF",
                                                fontSize: isMobile ? "12px" : "14px",
                                                fontWeight: "bold",
                                                paddingLeft: activeCardIndex === index ? "5%" : "0",
                                                marginTop: activeCardIndex === index ? "0" : "10px",
                                            }}
                                        >
                                            {card.bankName}
                                        </Typography>
                                    </Grid>
                                    {activeCardIndex === index && (
                                        <>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    marginTop: '5%',
                                                    color: "yellow",
                                                    fontSize: isMobile ? "13px" : "15px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {card.company}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: "white",
                                                    fontSize: isMobile ? '13px' : '15px',
                                                    paddingTop: '4%',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Account No: {card.accountNo}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: "white",
                                                    fontSize: '13px',
                                                    paddingTop: '2%',
                                                }}
                                            >
                                                IFSC: {card.ifsc}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: "white",
                                                    fontSize: '13px',
                                                    paddingTop: '2%',
                                                }}
                                            >
                                                Branch: {card.branch}
                                            </Typography>
                                        </>
                                    )}
                                    {activeCardIndex !== index && (
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleViewDetails(index)}
                                            style={{
                                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                                color: 'white',
                                                border: "1px solid white",
                                                marginTop: "auto",
                                                alignSelf: "center",
                                                fontSize: "10px",
                                            }}
                                        >
                                            View Details
                                        </Button>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Paper>
        </Grid>
    );
};

export default MyCards;
