import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux"; // Import Provider from react-redux
import store from "./redux/store"; // Import your Redux store
import Layout from "./Layout";
import Aboutus from "./components/WebSite/Aboutus";
import SdListTable from "./components/Partner/SdList";
import MdlistTable from "./components/Partner/MdList";
import DlistTable from "./components/Partner/DList";
import RetailerlistTable from "./components/Partner/RetailerList";
import TechSupport from "./components/Partner/TechSupport";
import FixCommission from "./components/Compensetion/FixCommission";
import VariableCommission from "./components/Compensetion/VariableCommission";
import TicketHandling from "./components/Support/TicketHandlingSupport";
import SendSms from "./components/Setting/SendSms";
import GeneralSettings from "./components/Setting/GeneralSetting";
import Flights from "./components/retailerservicespages/Flights";
import BookingComponent from "./components/retailerservicespages/BookingComponent";
import Sprintnxt from "./components/Sprintnxt";
import OngoingTicket from "./components/Support/OngoingTicket";
import CloseTicket from "./components/Support/CloseTicket";
import PendingTicket from "./components/Support/PendingTicket";
import RBusTicketsBook from "./components/Services/BusTicketBook/RBusTicketsBook";
import DetailedBusComponent from "./components/Services/BusTicketBook/DetailedBusComponent";
import SetMpin from "./components/Login/SetMpin";
import SeatSelection from "./components/Services/BusTicketBook/SeatSelection";
import RRecharge from "./components/Services/RRecharge";
import GasCylinderBooking from "./components/Services/GasCylinderBooking";
import BroadBand from "./components/Services/BroadBand";
import LandLine from "./components/Services/LandLine";
import PostPaidRecharge from "./components/Services/PostPaidRecharge";
import RetailerServices from "./components/Services/RetailerServices";
import DthRecharge from "./components/Services/DthRecharge";
import RetailerWater from "./components/Services/RetailerWater";
import RPipedGas from "./components/Services/RPipedGas";
import RMuncipalTax from "./components/Services/RMuncipalTax";
import REducationFees from "./components/Services/REducationFees";
import RElectricity from "./components/Services/RElectricity";
import FASTag from "./components/Services/FASTag";
import RPayLoanHouse from "./components/Services/RHouseRent";
import PassengerDetail from "./components/Services/TrainTicketBooking/PassengerDetail";
import EditUserID from "./components/Services/TrainTicketBooking/EditUserID";
import TrainTicketBooking from "./components/Services/TrainTicketBooking/TrainTicketBooking";
import Home from "./components/WebSite/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import SignIn from "./components/WebSite/SignIn";
import Blogs from "./components/WebSite/Blogs";
import ContactUs from "./components/WebSite/ContactUs";
import Career from "./components/WebSite/Career";
import Gallery from "./components/WebSite/Gallery";
import Services from "./components/WebSite/Services";
import BlogsUploader from "../src/components/Setting/BlogsUploader";
import RCableTv from "./components/Services/RCableTv";
import CplistTable from "./components/Partner/CpList";
import ProtectedRoute from "./ProtectedRoute"; // Import the ProtectedRoute component
import Privacypolicy from "./components/WebSite/Privacypolicy";
import TermsandConditions from "./components/WebSite/TermsandConditions";
import Faq from "./components/WebSite/Faq";
import RefundandCancel from "./components/WebSite/RefundandCancel";
import TransactionFailed from "./components/Balance/TransactionFailed";
import PayInndOut from "./components/Balance/PayInAndOut";
import OurTeam from "./components/WebSite/OurTeam";
import UserProfile from "./components/Dashboard/UserProfile";
import SuccessPop from "./components/Balance/SuccessPopup";
import FailedPop from "./components/Balance/FailedPopup";
import UserCreationSuccessPopup from "./components/Partner/UserCreationSuccessPopup";
import UserCreationFailedPopup from "./components/Partner/UserCreationFailedPopup";
import SignInK from "./components/WebSite/SignInK";
import BankChargesTable from "./components/Partner/BankChargesTable";
import RetailerLoanPay from "./components/Services/RetailerLoanPay";
import RetailerMetro from "./components/Services/RetailerMetro";
import RetailerToll from "./components/Services/RetailerToll";
import Testpayin from "./components/Balance/testpayin";
import CPL from "./components/Partner/CPL";
import SDL from "./components/Partner/SDL";

// import Navbar from './components/Dashboard/Navbar';
import DeepLinkPage from "./components/Balance/deeplinkpage";
import MDL from "./components/Partner/MDL";
import ADL from "./components/Partner/ADL";
import RList from "./components/Partner/RList";
import APITable from "./components/Balance/APITable";
import TechS from "./components/Partner/TechS";
import NewKycVerification from "./components/Partner/NewKycVerification";
import NewFundTransfer from "./components/Balance/NewFundTransfer";
import WalletBalanceComponent from "./components/Balance/WalletBalanceComponent";
import NewPayout from "./components/Balance/NewPayout";
import NewAddBeneficiary from "./components/Balance/NewAddBeneficiary";
import NewReverseMoney from "./components/Balance/NewReverseMoney";
import NewBankCharges from "./components/Balance/NewBankCharges";
import Dashboard2 from "./components/Dashboard/Dashboard2";
import NewFixandVariableCom from "./components/Compensetion/NewFixandVariableCom";
import NewPayIn from "./components/Reports/NewPayIn";
import NewPayOutReport from "./components/Reports/NewPayOutReport";
import PayBills from "./components/Services/PayBills";
import BookandBuy from "./components/Services/BookandBuy";
import NewFundTReport from "./components/Reports/NewFTR";
import NewFTRTable from "./components/Reports/NewFTRTable";
import DeepPayout from "./components/Balance/DeepPayout";
import NewTransactionalReport from "./components/Reports/NewTransactionalReport";
import NewTransactionalTable from "./components/Reports/NewTransactionalTable";
import NewUserCashback from "./components/Reports/NewUserCashback";
import NewTransactionalHistoryR from "./components/Reports/NewTransactionalHistoryR";
import NewBalanceReqHis from "./components/Reports/NewBalanceReqHis";
import NewSurchargeReport from "./components/Reports/NewSurchargeReport";
import NewCommissionReport from "./components/Reports/NewCommissionReport";
import NewPaymentRequest from "./components/NewPaymentRequest";
import NewCreateTicket from "./components/NewCreateTicket";
import NewFundTransferTicket from "./components/Support/NewFundTransferTicket";
import NewUserCreation from "./components/Support/NewUserCreation";
import NewChangePSupport from "./components/Support/NewChangePSupport";
import MovieTicket from "./components/Services/MovieTicket";
import NewBlogUpdater from "./components/Setting/NewBlogUpdater";
import ChangeParentNew from "./components/Partner/ChangeParentNew";
import QRCodeComp from "./components/Balance/QRCodeComp";
import TransactionSuccess from "./components/Balance/TransactionSuccess";
import InsufficiantB from "./components/Balance/InsufficiantB";
import AddBeneSuccessPop from "./components/Balance/AddBeneSuccessPop";
import Successtoverify from "./components/Balance/Successtoverify";
import Failedtoverify from "./components/Balance/Failedtoverify";
import Failedtoaddbene from "./components/Balance/Failedtoaddbene";
import MoneyAddedSucc from "./components/Balance/MoneyAddedSucc";
import MoneyFailedtoAdd from "./components/Balance/MoneyFailedtoAdd";
import PayoutPopup from "./components/Balance/PayoutPopup";
import PayoutFailedPopup from "./components/Balance/PayoutFailedPopup";
import AccPresentPopup from "./components/Balance/AccPresentPopup";
import RetailerPieCharts from "./components/Dashboard/RetailerPieCharts";
import Dashboard3 from "./components/DashboardNew/DashboardLayoutAccount";
import UserNotExist from "./components/Balance/UserNotExist";
// import DashboardLayoutAccount from "./components/Reports/DashboardLayoutAccount";
import NewLandline from "./components/Services/NewLandline";
import CreditCard from "./components/Services/CreditCard";
// import NewElectricity from "./components/Services/NewElectricity";
import RegisterComplaint from "./components/Services/RegisterComplaint";
import ComplaintInvoice from "./components/Services/ComplaintInvoice";
import AllVirtualAcc from "./components/VirtualAccount/AllVirtualAcc";
import VirtualAccReqList from "./components/VirtualAccount/VirtualAccReqList";
import VirtualTrans from "./components/VirtualAccount/VirtaulTrans";
import HierarchyTrans from "./components/VirtualAccount/HierarchyTrans";
import QRCode from "./components/VirtualAccount/QRCode";
import BillInvoicePopup from "./components/Services/BillInvoicePopup";
import NewElectricity from "./components/Services/NewElectricity";
import CompalaintRegistration from "./components/Services/CompalaintRegistration";
import AddMoneyNew from "./components/Balance/AddMoneyNew";
import DeleteBeneficiarySucc from "./components/Balance/DeleteBeneficiarySucc";
import DeleteBeneficiaryFail from "./components/Balance/DeleteBeneficiaryFail";
import IpinModal from "./components/IpaisaMpin/IpinModal";
import LandingPage2 from "./components/IpaisaMpin/LandingPage2";
import ResetPassword2 from "./components/IpaisaMpin/ResetMpin2";
import ForgotPin2 from "./components/IpaisaMpin/ForgotPin2";
import SetPassword2 from "./components/IpaisaMpin/SetIPMpin2";
import ChangeIPin from "./components/IpaisaMpin/ChangeIPin";
import Payout from "./components/Balance/Payout";
import { CCBillPayList } from "./components/Services/CCBillPayList";
import PayoutReportInvoice from "./components/Reports/PayoutReportInvoice";
import SvgImageComponent from "./components/SvgImageComponent";
import BeneChargesTable from "./components/Partner/BeneCharges";
import ComplaintRegisFail from "./components/Services/ComplaintRegisFail";
import PayinReportInvoice from "./components/Reports/PayinReportInvoice";
import FTRReportsInvoice from "./components/Reports/FTRReportsInvoice";
import BeneficiaryReport from "./components/Reports/BeneficiaryReport";
import PendingRefundReport from "./components/Reports/PendingRefundReport";
import ReverseMoneyReports from "./components/Balance/ReverseMoneyReports";
import ReverseMoneySucc from "./components/Balance/ReverseMoneySucc";
import ReverseMoneyFail from "./components/Balance/ReverseMoneyFail";
import DateSelectorandSearch from "./components/Reports/DateSelectorandSearch";
import DeletedUserTable from "./components/Reports/DeletedUserTable";
import PaySuccess from "./components/Balance/PaySuccess";
import DirectFTR from "./components/Balance/DirectFTR";
import DeepLinkClientReg from "./components/Balance/DeepLinkClientReg";
import PayoutChecker from "./components/Balance/PayoutChecker";
import PayinHierarchywise from "./components/Reports/PayinHierarchywise";
import PayoutHierarchywise from "./components/Reports/PayoutHierarchywise";
import HierarchyReport from "./components/Reports/HierarchyReport/HierarchyReport";
import CardandBarChart from "./components/Reports/CardandBarChart";
import HiePayInInvoice from "./components/Reports/HierarchyReport/HiePayInInvoice";
import HiePayoutInvoice from "./components/Reports/HierarchyReport/HiePayoutInvoice";
import CompeletedTrans from "./components/Reports/CompeletedTrans";
import CompletedTransInvoice from "./components/Reports/CompletedTransInvoice";
import MainPayout from "./components/Balance/MainPayout/MainPayout";
import PaymentMethodChangeEvent from "./components/Balance/PaymentMethodChangeEvent";
import CardChargesTable from "./components/Partner/CardChargesTable";
import PayinServices from "./components/SerivceHandler/Payin/PayinServices";
import Greeting from "./components/FinalDashboard/Greeting";
import Cards from "./components/FinalDashboard/Cards";
import Records from "./components/FinalDashboard/Records";
import TotalBalanceChart from "./components/FinalDashboard/TotalBalanceChart";
import PerformanceGraph from "./components/FinalDashboard/PerformanceGraph";
import MyCards from "./components/FinalDashboard/MyCards";
import DashboardFinal from "./components/FinalDashboard/DashboardFinal";
import AllRazorPayQRList from "./components/RazorPayQrCode/AllRazorPayQRList";
import RazorPayQRCode from "./components/RazorPayQrCode/RazorPayQRCode";


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path='/signinK' element={<SignInK />} />
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            {/* <Route path="/dashboard3" element={<Dashboard3 />} /> */}
            <Route path="/signinK" element={<SignInK />} />
            <Route path="/dashboard2" element={<ProtectedRoute><Dashboard2 /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><DashboardFinal /></ProtectedRoute>} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/ourteam" element={<OurTeam />} />
            <Route path="/services" element={<Services />} />
            {/* <Route path="/deeplinkpage/*" element={<PaySuccess />} /> */}
            <Route path="/deeplinkpage/*" element={<DeepLinkPage />} />
            {/* <Route path="/PaySuccess" element={<PaySuccess />} /> */}
            <Route path="/deeplinkpayout/*" element={<DeepPayout />} />

            {/* Footer */}
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/CancellationandRefund" element={<RefundandCancel />} />
            <Route path="/termsandconditions" element={<TermsandConditions />} />
            <Route path="/iPaisaQRCode" element={<ProtectedRoute><QRCodeComp /></ProtectedRoute>} />
            <Route path="/DateSelectorandSearchComp" element={<DateSelectorandSearch />} />
            <Route path="/loader" element={<SvgImageComponent />} />
            <Route path="/userprofile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
            {/* <Route path="/navbar" element={<Navbar/>} /> */}


            {/* All Payments Invoice Reports */}
            <Route path="/PayinReportInvoice" element={<PayinReportInvoice />} />
            <Route path="/PayOutReportsInvoice" element={<PayoutReportInvoice />} />
            <Route path="/FTRReportsInvoice" element={<FTRReportsInvoice />} />
            <Route path="/HierarchyPayinInvoice" element={<HiePayInInvoice />} />
            <Route path="/HierarchyPayoutInvoice" element={<HiePayoutInvoice />} />
            <Route path="/CompletedTransInvoice" element={<CompletedTransInvoice />} />


            {/* All Success and Failed Popups */}

            <Route path="/successpop" element={<SuccessPop />} />
            <Route path="/failedpop" element={<FailedPop />} />
            <Route path="/transationSuccess" element={<TransactionSuccess />} />
            <Route path="/transactionfailed" element={<TransactionFailed />} />
            <Route path="/insufficientbalance" element={<InsufficiantB />} />
            <Route path="/AddBenePop" element={<AddBeneSuccessPop />} />
            <Route path="/successtoverify" element={<Successtoverify />} />
            <Route path="/failedtoverify" element={<Failedtoverify />} />
            <Route path="/failedtoaddbene" element={<Failedtoaddbene />} />
            <Route path="/MoneyAddedSuccess" element={<MoneyAddedSucc />} />
            <Route path="/MoneyAddedFailed" element={<MoneyFailedtoAdd />} />
            <Route path="/PayoutSuccess" element={<PayoutPopup />} />
            <Route path="/PayoutFailed" element={<PayoutFailedPopup />} />
            <Route path="/AccountisPresentPopup" element={<AccPresentPopup />} />
            <Route path="/PaySuccess" element={<PaySuccess />} />
            <Route path="/ReverseMoneySuccess" element={<ReverseMoneySucc />} />
            <Route path="/ReverseMoneyFailure" element={<ReverseMoneyFail />} />
            <Route path="/successpop" element={<SuccessPop />} />
            <Route path="/failedpop" element={<FailedPop />} />
            <Route path="/usercreationsuccess" element={<UserCreationSuccessPopup />} />
            <Route path="/usercreationfailed" element={<UserCreationFailedPopup />} />
            <Route path="/UserNotExist" element={<UserNotExist />} />
            <Route path="/ComplaintFailure" element={<ComplaintRegisFail />} />
            <Route path="/benecharges" element={<BeneChargesTable />} />
            <Route path="/cardcharges" element={<CardChargesTable />} />

            {/* <Route path="/transactionsuccess" element={<TransactionSuccessFull/>} /> */}

            {/* Final Dashboard */}
            {/* <Route path="/DashboardFinal" element={<DashboardFinal />} /> */}
            <Route path="/Greeting" element={<Greeting/>} />
            <Route path="/Cards" element={<Cards />} />
            <Route path="/Records" element={<Records />} /> 
            <Route path="/TotalBalanceChart" element={<TotalBalanceChart />} />
            <Route path="/PerformanceGraph" element={<PerformanceGraph />} />
            {/* <Route path="/MyCards" element={<MyCards />} /> */}

            {/* Partners */}

            <Route path="/cplist" element={<ProtectedRoute><CplistTable /></ProtectedRoute>} />
            <Route path="/sdlist" element={<ProtectedRoute><SdListTable /></ProtectedRoute>} />
            <Route path="/mdlist" element={<ProtectedRoute><MdlistTable /></ProtectedRoute>} />
            <Route path="/adlist" element={<ProtectedRoute><DlistTable /></ProtectedRoute>} />
            <Route path="/retailer" element={<ProtectedRoute><RetailerlistTable /></ProtectedRoute>} />
            <Route path="/techsupport" element={<ProtectedRoute><TechSupport /></ProtectedRoute>} />
            <Route path="/requestpaymentissue" element={<ProtectedRoute><NewPaymentRequest /></ProtectedRoute>} />
            <Route path="/kycverification" element={<ProtectedRoute><NewKycVerification /></ProtectedRoute>} />
            <Route path="/changeparent" element={<ProtectedRoute><ChangeParentNew /></ProtectedRoute>} />

            {/* New Partners Table but Note:  it is not Live */}
            <Route path="/ChannelPartners" element={<CPL />} />
            <Route path="/SuperDistributors" element={<SDL />} />
            <Route path="/MasterDistributors" element={<MDL />} />
            <Route path="/AreaDistributors" element={<ADL />} />
            <Route path="/Retailers" element={<RList />} />
            <Route path="/TechSupportNew" element={<TechS />} />


            {/* Reports */}

            <Route path="/FundTransferReports" element={<ProtectedRoute><NewFundTReport /></ProtectedRoute>} />
            <Route path="/TransactionalReport" element={<ProtectedRoute><NewTransactionalReport /></ProtectedRoute>} />
            <Route path="/TransactionalHistory" element={<ProtectedRoute><NewTransactionalHistoryR /></ProtectedRoute>} />
            <Route path="/commissionreport" element={<ProtectedRoute><NewCommissionReport /></ProtectedRoute>} />
            <Route path="/surchargereport" element={<ProtectedRoute><NewSurchargeReport /></ProtectedRoute>} />
            <Route path="/balReqHisReport" element={<ProtectedRoute><NewBalanceReqHis /></ProtectedRoute>} />
            <Route path="/userCashbackReport" element={<ProtectedRoute><NewUserCashback /></ProtectedRoute>} />
            <Route path="/fixCommission" element={<ProtectedRoute><FixCommission /></ProtectedRoute>} />
            <Route path="/variablecommission" element={<ProtectedRoute><VariableCommission /></ProtectedRoute>} />
            <Route path="/pendingreports" element={<ProtectedRoute><PendingRefundReport /></ProtectedRoute>} />
            <Route path="/userdropdown" element={<HierarchyReport />} />
            <Route path="/benereport" element={<BeneficiaryReport />} />
            <Route path="/PayinHierarchywise" element={<PayinHierarchywise />} />
            <Route path="/PayoutHierarchywise" element={<PayoutHierarchywise />} />
            <Route path="/TotalPayout" element={<CardandBarChart />} />
            <Route path="/payinndout" element={<ProtectedRoute><PayInndOut /></ProtectedRoute>} />
            <Route path="/testpayin" element={<ProtectedRoute><Testpayin /></ProtectedRoute>} />
            <Route path="/payinreport" element={<ProtectedRoute><NewPayIn /></ProtectedRoute>} />
            <Route path="/payoutreport" element={<ProtectedRoute><NewPayOutReport /></ProtectedRoute>} />
            <Route path="/deletedusertable" element={<ProtectedRoute><DeletedUserTable /></ProtectedRoute>} />
            <Route path="/NewFTRTable" element={<ProtectedRoute><NewFTRTable /></ProtectedRoute>} />
            <Route path="/NewTransactionalTable" element={<ProtectedRoute><NewTransactionalTable /></ProtectedRoute>} />
            <Route path="/ReverseMoneyReports" element={<ReverseMoneyReports />} />
            <Route path="/DeepLinkClientReg" element={<DeepLinkClientReg />} />
            <Route path="/PayoutChecker" element={<PayoutChecker />} />
            <Route path="/payinservices" element={<PayinServices />} />
            <Route path="/CompletedTransactions" element={<ProtectedRoute><CompeletedTrans /></ProtectedRoute>} />
            <Route path="/allrazorqrlist" element={<ProtectedRoute><AllRazorPayQRList /></ProtectedRoute>} />


            {/* Balance */}
            <Route path="/directftr" element={<ProtectedRoute><DirectFTR /></ProtectedRoute>} />
            <Route path="/fundtransfer" element={<ProtectedRoute><NewFundTransfer /></ProtectedRoute>} />
            <Route path="/APITable" element={<ProtectedRoute><APITable /></ProtectedRoute>} />
            <Route path="/reversemoney" element={<ProtectedRoute><NewReverseMoney /></ProtectedRoute>} />
            <Route path="/addmoney" element={<ProtectedRoute><AddMoneyNew /></ProtectedRoute>} />
            <Route path="/addmoneyr" element={<ProtectedRoute><PaymentMethodChangeEvent /></ProtectedRoute>} />

            <Route path="/payout" element={<ProtectedRoute><NewPayout /></ProtectedRoute>} />
            <Route path="/payoutn" element={<ProtectedRoute><MainPayout /></ProtectedRoute>} />

            <Route path="/addBeneficiary" element={<ProtectedRoute><NewAddBeneficiary /></ProtectedRoute>} />
            <Route path="/bankcharges" element={<ProtectedRoute><BankChargesTable /></ProtectedRoute>} />
            <Route path="/WalletBalance" element={<WalletBalanceComponent />} />
            <Route path="/NewBankCharges" element={<NewBankCharges />} />
            <Route path="/NewFixandVariable" element={<NewFixandVariableCom />} />

            {/* Supports */}

            <Route path="/createticket" element={<ProtectedRoute><NewCreateTicket /></ProtectedRoute>} />
            <Route path="/fundtransferticket" element={<ProtectedRoute><NewFundTransferTicket /></ProtectedRoute>} />
            <Route path="/usercreationticket" element={<ProtectedRoute><NewUserCreation /></ProtectedRoute>} />
            <Route path="/tickethandling" element={<ProtectedRoute><TicketHandling /></ProtectedRoute>} />
            <Route path="/ongoingticket" element={<ProtectedRoute><OngoingTicket /></ProtectedRoute>} />
            <Route path="/closeticket" element={<ProtectedRoute><CloseTicket /></ProtectedRoute>} />
            <Route path="/pendingticket" element={<ProtectedRoute><PendingTicket /></ProtectedRoute>} />



            {/* Setting */}

            <Route path="/sendsms" element={<ProtectedRoute><SendSms /></ProtectedRoute>} />
            <Route path="/generalsetting" element={<ProtectedRoute><GeneralSettings /></ProtectedRoute>} />
            <Route path="/mpinlanding" element={<LandingPage2 />} />
            <Route path="/setipaisampin" element={<SetPassword2 />} />
            <Route path="/resetipaisampin" element={<ResetPassword2 />} />
            <Route path="/forgotipaisampin" element={<ForgotPin2 />} />
            <Route path="/ipinmodal" element={<IpinModal />} />
            <Route path="/changeipin" element={<ChangeIPin />} />
            <Route path="/SetMpin" element={<ProtectedRoute><SetMpin /></ProtectedRoute>} />


            {/* Retailer Services */}

            <Route path="/BookandBuy" element={<ProtectedRoute><BookandBuy /></ProtectedRoute>} />
            <Route path="/PayBills" element={<ProtectedRoute><PayBills /></ProtectedRoute>} />
            <Route path="/RetailersPieChart" element={<RetailerPieCharts />} />
            <Route path="/rmovieticket" element={<ProtectedRoute><MovieTicket /></ProtectedRoute>} />
            <Route path="/flights" element={<ProtectedRoute><Flights /></ProtectedRoute>} />
            <Route path="/booking" element={<ProtectedRoute><BookingComponent /></ProtectedRoute>} />
            <Route path="/retailerservices" element={<ProtectedRoute><RetailerServices /></ProtectedRoute>} />
            <Route path="/rapartments" element={<ProtectedRoute><RPayLoanHouse /></ProtectedRoute>} />
            <Route path="/dthrecharge" element={<ProtectedRoute><DthRecharge /></ProtectedRoute>} />
            <Route path="/CreditCard" element={<ProtectedRoute><CreditCard /></ProtectedRoute>} />
            <Route path="/rectricityrecharge" element={<ProtectedRoute><RElectricity /></ProtectedRoute>} />
            <Route path="/retailerwater" element={<ProtectedRoute><RetailerWater /></ProtectedRoute>} />
            <Route path="/rpipegas" element={<ProtectedRoute><RPipedGas /></ProtectedRoute>} />
            <Route path="/rmuncipaltax" element={<ProtectedRoute><RMuncipalTax /></ProtectedRoute>} />
            <Route path="/reducationfees" element={<ProtectedRoute><REducationFees /></ProtectedRoute>} />
            <Route path="/rflightbook" element={<ProtectedRoute><Flights /></ProtectedRoute>} />
            <Route path="/rrecharge" element={<ProtectedRoute><RRecharge /></ProtectedRoute>} />
            <Route path="/gascylinderbooking" element={<ProtectedRoute><GasCylinderBooking /></ProtectedRoute>} />
            <Route path="/rbroadband" element={<ProtectedRoute><BroadBand /></ProtectedRoute>} />
            <Route path="/landline" element={<ProtectedRoute><LandLine /></ProtectedRoute>} />
            <Route path='/retailerLoanPay' element={<ProtectedRoute><RetailerLoanPay /></ProtectedRoute>} />
            <Route path='/retailerCableTv' element={<ProtectedRoute><RCableTv /></ProtectedRoute>} />
            <Route path="/metro" element={<ProtectedRoute><RetailerMetro /></ProtectedRoute>} />
            <Route path='/toll' element={<ProtectedRoute><RetailerToll /></ProtectedRoute>} />
            <Route path="/postpaidrecharge" element={<ProtectedRoute><PostPaidRecharge /></ProtectedRoute>} />
            <Route path="/fasttagrecharge" element={<ProtectedRoute><FASTag /></ProtectedRoute>} />
            <Route path="/DetailedBusComponent" element={<ProtectedRoute><DetailedBusComponent /></ProtectedRoute>} />
            <Route path="/rbustickets" element={<ProtectedRoute><RBusTicketsBook /></ProtectedRoute>} />
            <Route path="/SeatSelection" element={<ProtectedRoute><SeatSelection /></ProtectedRoute>} />
            <Route path="/sprintnxt" element={<ProtectedRoute><Sprintnxt /></ProtectedRoute>} />
            <Route path="/trainticket" element={<ProtectedRoute><TrainTicketBooking /></ProtectedRoute>} />
            <Route path="/passengerdetails" element={<ProtectedRoute><PassengerDetail /></ProtectedRoute>} />
            <Route path="/edituserid" element={<ProtectedRoute><EditUserID /></ProtectedRoute>} />
            <Route path="/blogsupdater" element={<ProtectedRoute><NewBlogUpdater /></ProtectedRoute>} />
            <Route path="/blogsuploader" element={<ProtectedRoute><BlogsUploader /></ProtectedRoute>} />
            <Route path="/changeparentsupport" element={<ProtectedRoute><NewChangePSupport /></ProtectedRoute>} />
            <Route path="/PayoutFailed" element={<PayoutFailedPopup />} />
            <Route path="DeleteBeneficiarySucc" element={<DeleteBeneficiarySucc />} />
            <Route path="/DeleteBeneficiaryFail" element={<DeleteBeneficiaryFail />} />
            <Route path="/payout2" element={<Payout />} />
            <Route path="/RetailersPieChart" element={<RetailerPieCharts />} />
            <Route path="/NewLandline" element={<NewLandline />} />
            <Route path="/NewElectricity" element={<NewElectricity />} />
            <Route path="/ComplaintRegistration" element={<CompalaintRegistration />} />
            <Route path="/ComplaintInvoice" element={<ComplaintInvoice />} />
            <Route path="/BillInvoicePopup" element={<BillInvoicePopup />} />
            <Route path="/RegisterComplaints" element={<RegisterComplaint />} />

            {/* prajawal */}
            <Route path="/applyqr" element={<ProtectedRoute> <VirtualAccReqList /> </ProtectedRoute>} />
            <Route path="/virtualtrans" element={<ProtectedRoute> <VirtualTrans /> </ProtectedRoute>} />
            <Route path="/htrans" element={<ProtectedRoute> <HierarchyTrans /> </ProtectedRoute>} />
            <Route path="/vlist" element={<ProtectedRoute> <AllVirtualAcc /> </ProtectedRoute>} />
            <Route path="/ccbilllist" element={<ProtectedRoute> <CCBillPayList /> </ProtectedRoute>} />
            <Route path="/qrcode" element={<ProtectedRoute> <QRCode /> </ProtectedRoute>} />
            <Route path="/razorpayqrcode" element={<ProtectedRoute> <RazorPayQRCode /> </ProtectedRoute>} />
            <Route path="*" element={<SignIn />} />
          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
