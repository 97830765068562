import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Typography } from '@mui/material';
import { fetchWalletBalance } from '../../redux/actions/dashboardActions';
import { ReactComponent as ApiBalanceIcon } from '../Assets/Dashboard/apiBalance.svg';
import { ReactComponent as WalletBalance } from '../Assets/Dashboard/walletBalance.svg';
import { ReactComponent as IWalletBalance } from '../Assets/Dashboard/iWallet.svg';
import { ReactComponent as IAtmBalance } from '../Assets/Dashboard/iAtm.svg';

const cardData = [
    {
        title: "API Balance",
        amount: "0.00",
        backgroundColor: "#00178D",
        icon: <ApiBalanceIcon style={{ fontSize: '1rem', color: '#fff' }} />
    },
    {
        title: "Wallet Balance",
        amount: "0.00",
        backgroundColor: "#008D39",
        icon: <WalletBalance style={{ fontSize: '3rem', color: '#fff' }} />
    },
    {
        title: "iIT",
        amount: "0.00",
        backgroundColor: "#FF8C00",
        icon: <IWalletBalance style={{ fontSize: '3rem', color: '#fff' }} />
    },
    {
        title: "iATM",
        amount: "0.00",
        backgroundColor: "#77279D",
        icon: <IAtmBalance style={{ fontSize: '3rem', color: '#fff' }} />
    },
];

const Cards = () => {
    const dispatch = useDispatch();

    // Selectors to get the wallet balance from the Redux store
    const walletBalance = useSelector(state => state.dashboard.walletBalance);
    const uType = localStorage.getItem("uType"); // Assuming user type is stored in localStorage

    useEffect(() => {
        // Fetch wallet balance when the component mounts
        dispatch(fetchWalletBalance());
    }, [dispatch]);

    // Filter card data based on user type (uType)
    const filteredCardData = () => {
        if (uType === "ADMIN") {
            return cardData.filter(card => (card.title !== "iATM") && (card.title !== "iIT")); // Show all cards for ADMIN
        } else if (uType === "RETAILER") {
            return cardData.filter(card => card.title !== "API Balance"); // Show all except API Balance for RETAILER
        } else {
            return cardData.filter(card => card.title === "Wallet Balance"); // Only show Wallet Balance for other users
        }
    };

    return (
        <Paper style={{ padding: '1rem', borderRadius: '18px' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>Balance</Typography>
            <Grid container spacing={2} style={{ marginTop: '1%' }}>
                {filteredCardData().map((card, index) => (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                        <Paper
                            elevation={3}
                            style={{
                                padding: '1.5rem',
                                backgroundColor: card.backgroundColor,
                                color: '#fff',
                                borderRadius: '12px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            {card.icon}
                            <Typography style={{ marginTop: '0.5rem', fontSize: '12px' }}>{card.title}</Typography>
                            <Typography style={{ fontWeight: 'bold' }}>
                                {/* Update the Wallet Balance card with the formatted value */}
                                {card.title === "Wallet Balance" ? walletBalance.toFixed(2) : card.amount}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default Cards;
