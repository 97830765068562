import React from 'react';
import { Grid, Container } from '@mui/material';
import Greeting from '../FinalDashboard/Greeting';
import Cards from '../FinalDashboard/Cards';
import Records from '../FinalDashboard/Records';
import TotalBalanceChart from '../FinalDashboard/TotalBalanceChart';
import PerformanceGraph from '../FinalDashboard/PerformanceGraph';
import MyCards from '../FinalDashboard/MyCards';


const DashboardFinal = () => (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Greeting />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Cards />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Records />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TotalBalanceChart />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PerformanceGraph />
            </Grid>
            <Grid item xs={12}>
                <MyCards />
            </Grid>
        </Grid>
    </Container>
);

export default DashboardFinal;
