import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, TablePagination,
    Container, IconButton
} from '@mui/material';
import { toast } from 'react-toastify';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import html2pdf from 'html2pdf.js';
import PendingRefundReportsInvoice from './PendingRefundReportsInvoice';


const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));

const PendingRefundReport = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [isNextPageEnabled, setIsNextPageEnabled] = useState(false);
    const classes = useStyles();

    const fetchPendingRefundReport = async (pageNumber = 0, pageSize = 5) => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem('jwtToken');
            if (!token) {
                toast.error('Authentication token not found. Please log in.');
                setLoading(false);
                return;
            }

            const response = await axios.get(
                `https://testapi.ipaisa.co.in/myapp/admin/getPending-refundPoutTxns?page=${pageNumber}&size=${pageSize}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data && response.data.body.transactions) {
                setTransactions(response.data.body.transactions);
                setTotalItems(response.data.body.totalItems || 0);

                setIsNextPageEnabled(response.status === 200);
            } else {
                toast.error('No transactions found.');
                setTotalItems(0);
                setIsNextPageEnabled(false);
            }
        } catch (error) {
            console.error('Error fetching pending refund report:', error);
            setError('Failed to load data.');
            toast.error('Error fetching pending refund report. Please try again later.');
            setTotalItems(0);
            setIsNextPageEnabled(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPendingRefundReport(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        if (isNextPageEnabled || newPage < page) {
            setPage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownloadInvoice = async (transaction) => {
        const receiptElement = document.getElementById(`receipt-${transaction.txnReferenceId}`);

        if (!receiptElement) {
            console.error('Receipt element not found:', `receipt-${transaction.txnReferenceId}`);
            return;
        }

        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5],
            filename: `Payout Receipt-${transaction.txnReferenceId}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4' }
        };

        try {
            await html2pdf().from(receiptElement).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF: ', error);
        }
    };

    return (
        <Container style={{ padding: '1rem', marginTop: 'calc(4% + 58px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Reports</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Pending Refund Report</Typography>
            </Grid>
            <Grid style={{ marginTop: '1%' }}>
                <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Pending Refund Report</Typography>
            </Grid>

            <Grid style={{ marginTop: '1%' }}>
                <TableContainer
                    component={Paper}
                    sx={{
                        marginTop: '2%',
                        maxWidth: '100%',
                        overflowX: 'auto',
                        borderRadius: '12px 12px 0px 0px',
                    }}
                >
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <Table>
                            <TableHead sx={{ backgroundColor: '#027BFE' }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>ID</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Status</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>External Reference</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Payer Account</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Payee Account</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Payee Name</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Transaction Value</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Transfer Mode</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Timestamp</TableCell>
                                    <TableCell sx={{ color: 'white', textAlign: 'start' }}>Invoice</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map((transaction, index) => (
                                    <TableRow key={transaction.id}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell>{transaction.id}</TableCell>
                                        <TableCell>{transaction.status}</TableCell>
                                        <TableCell>{transaction.externalRef}</TableCell>
                                        <TableCell>{transaction.payerAccount}</TableCell>
                                        <TableCell>{transaction.payeeAccount}</TableCell>
                                        <TableCell>{transaction.payeeName}</TableCell>
                                        <TableCell>{transaction.txnValue}</TableCell>
                                        <TableCell>{transaction.transfer_mode}</TableCell>
                                        <TableCell>{new Date(transaction.timestamp).toLocaleString()}</TableCell>
                                        <TableCell align="center">
                                            <DownloadIcon
                                                onClick={() => handleDownloadInvoice(transaction)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </TableCell>
                                        <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                            <Grid id={`receipt-${transaction.txnReferenceId}`}>
                                                <PendingRefundReportsInvoice data={transaction} />
                                            </Grid>
                                        </Grid>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>
                {!loading && !error && (
                    <TablePagination
                        component="div"
                        count={totalItems}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        sx={{ marginTop: 2 }}
                        nextIconButtonProps={{ disabled: !isNextPageEnabled }}
                    />
                )}
            </Grid>
        </Container>
    );
};

export default PendingRefundReport;
