import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Container, Typography, Grid, TableFooter, TablePagination, CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import  html2pdf  from 'html2pdf.js';
import ReverseMoneyInvoice from './ReverseMoneyInvoice';

const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));

const ReverseMoneyReports = () => {
    const classes = useStyles();
    const [transactions, setTransactions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [noData, setNoData] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    const fetchReverseMoneyTxn = async (page = 0, rowsPerPage = 10) => {
        const jwtToken = localStorage.getItem('jwtToken');
        setLoading(true);
        try {
            const response = await fetch(`https://testapi.ipaisa.co.in/myapp/admin/rerseMoneyTxn?page=${page}&limit=${rowsPerPage}`, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.body.length === 0) setNoData(true);
                setTransactions(data.body);
                setTotalRecords(data.total);
            } else {
                console.error('Error fetching transactions:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching transactions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReverseMoneyTxn(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage]);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage + 1);
    };
    const handleDownloadInvoice = async (data) => {
        const receiptElement = document.getElementById(`receipt-${data.payinUserId}`);

        if (!receiptElement) {
            console.error('Receipt element not found:', `receipt-${data.payinUserId}`);
            return;
        }

        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5],
            filename: `Payout Receipt-${data.payinUserId}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4' }
        };

        try {
            await html2pdf().from(receiptElement).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF: ', error);
        }
    };
    const handleRowsPerPageChange = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1); // Reset to first page when rows per page changes
    };

    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color="textSecondary" style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Report</Typography>
                <PartnerArrowIcon />
                <Typography color="textSecondary" style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Reverse Money Report</Typography>
            </Grid>
            <Grid style={{ marginTop: '2%' }}>
                <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
                    Reverse Money Report
                </Typography>
            </Grid>

            {/* Table */}
            <Grid style={{ marginTop: '3%' }}>
                {loading ? (
                    <CircularProgress />
                ) : noData ? (
                    <Typography color="error" align="center">No data available for the selected date range</Typography>
                ) : (
                    <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
                        <Table>
                            <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }}>Sr. No.</TableCell>
                                    <TableCell style={{ color: 'white' }}>Transaction ID</TableCell>
                                    <TableCell style={{ color: 'white' }}>Receiver ID</TableCell>
                                    <TableCell style={{ color: 'white' }}>Reversed By</TableCell>
                                    <TableCell style={{ color: 'white' }}>Receiver Name</TableCell>
                                    <TableCell style={{ color: 'white' }}>Date & Time</TableCell>
                                    <TableCell style={{ color: 'white' }}>Opening Balance</TableCell>
                                    <TableCell style={{ color: 'white' }}>Amount</TableCell>
                                    <TableCell style={{ color: 'white' }}>Closing Balance</TableCell>
                                    <TableCell style={{ color: 'white' }}>Invoice</TableCell> {/* New Column */}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.length > 0 ? (
                                    transactions.map((txn, index) => (
                                        <TableRow key={txn.tId} className={index % 2 === 0 ? classes.oddRow : classes.evenRow}>
                                            <TableCell>{(currentPage) * rowsPerPage + index + 1}</TableCell>
                                            <TableCell>{txn.tId}</TableCell>
                                            <TableCell>{txn.receiverId}</TableCell>
                                            <TableCell>{txn.reverseBy}</TableCell>
                                            <TableCell>{txn.receiverName}</TableCell>
                                            <TableCell>{dayjs(txn.dt).format('DD/MM/YYYY hh:mm A')}</TableCell>
                                            <TableCell>{txn.userWltOpeningBlc ? txn.userWltOpeningBlc.toFixed(2) : 'N/A'}</TableCell>
                                            <TableCell>{txn.amount}</TableCell>
                                            <TableCell>{txn.userWltClosingBlc ? txn.userWltClosingBlc.toFixed(2) : 'N/A'}</TableCell>
                                            <TableCell align="center">
                                                <DownloadIcon
                                                    onClick={() => handleDownloadInvoice(txn)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </TableCell>
                                            <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                                <Grid id={`receipt-${txn.payinUserId}`}>
                                                    <ReverseMoneyInvoice data={txn} />
                                                </Grid>
                                            </Grid>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={9} align="center">No matching records found</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TableFooter style={{ display: 'flex', justifyContent: 'end' }}>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    count={totalRecords}
                                    rowsPerPage={rowsPerPage}
                                    page={currentPage}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                />
                            </TableRow>
                        </TableFooter>
                    </TableContainer>
                )}
            </Grid>
        </Container>
    );
};

export default ReverseMoneyReports;
