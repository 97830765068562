import { Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, Container, TablePagination } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, } from 'recharts';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: '#C1E0FB',
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  },
  PayInText: {
    color: '#027BFE',
  },
}));
const CardandBarChart = () => {
  const [data, setData] = useState([]);
  const [totalPayout, setTotalPayout] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [todayTransacted, setTodayTransacted] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTransactionId, setSearchTransactionId] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hasMore, setHasMore] = useState(true); // To track if there are more pages
  const [noContent, setNoContent] = useState(false); // New state for no transactions
  const classes = useStyles();

  const fetchTotalPayout = async (fromDate = '', toDate = '', page = 0, rowsPerPage = 10) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const url = new URL('https://testapi.ipaisa.co.in/myapp/admin/allPayout');
      if (fromDate) url.searchParams.append('fromDate', fromDate);
      if (toDate) url.searchParams.append('toDate', toDate);
      url.searchParams.append('page', page); // Send current page
      url.searchParams.append('limit', rowsPerPage); // Send rows per page as limit

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();

      if (response.ok) {
        const transactions = result.body.Transactions;
        const totalAmount = transactions.reduce(
          (sum, txn) => sum + parseFloat(txn.txnValue || 0),
          0
        );

        // Update your state here as needed
        setTotalPayout(totalAmount);
        setTotalTransactions(transactions.length);

        const today = new Date().toISOString().split('T')[0];
        const todayAmount = transactions
          .filter((txn) => txn.timestamp.split('T')[0] === today)
          .reduce((sum, txn) => sum + parseFloat(txn.txnValue || 0), 0);

        setTodayTransacted(todayAmount);

        // Get last 7 days data
        const last7DaysData = getLast7DaysData(transactions);
        setData(last7DaysData); // Store the last 7 days data
        setTableData(transactions); // Store all transactions
        setFilteredData(transactions); // Set filtered data

        // Check if we received fewer transactions than requested, indicating no more pages
        setHasMore(transactions.length >= rowsPerPage);
      } else {
        console.error('Failed to fetch total payout:', result);
      }
    } catch (error) {
      console.error('Error fetching total payout:', error);
    }
  };

  const getLast7DaysData = (transactions) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const today = new Date();
    const last7Days = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const dayName = days[date.getDay()];
      const dayDate = date.toISOString().split('T')[0];

      const payout = transactions
        .filter((txn) => txn.timestamp.split('T')[0] === dayDate)
        .reduce((sum, txn) => sum + parseFloat(txn.txnValue || 0), 0);

      last7Days.push({ day: dayName, payout });
    }
    return last7Days;
  };

  const handleSearch = () => {
    if (fromDate && toDate) {
      setTableData([]);
      setFilteredData([]);
      setData([]);
      fetchTotalPayout(fromDate, toDate);
    }
  };

  const handleClear = () => {
    setFromDate('');
    setToDate('');
    fetchTotalPayout();
  };

  const handleTransactionSearch = (event) => {
    const searchValue = event.target.value.trim(); // Remove extra spaces
    setSearchTransactionId(searchValue);
    const filteredTransactions = tableData.filter((txn) =>
      txn.txnReferenceId.includes(searchValue) || txn.payeeAccount.includes(searchValue) || txn.poolReferenceId.includes(searchValue) || txn.payeeName.includes(searchValue)
    );
    setFilteredData(filteredTransactions);
    setNoContent(filteredTransactions.length === 0);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page && hasMore) {
      // If the user is moving to the next page and there are more transactions, call the API
      fetchTotalPayout(fromDate, toDate, newPage, rowsPerPage);
    } else if (newPage < page && page > 0) {
      // If the user is moving to the previous page, call the API for the previous page
      fetchTotalPayout(fromDate, toDate, newPage, rowsPerPage);
    }
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchTotalPayout(fromDate, toDate, 0, newRowsPerPage);
  };

  useEffect(() => {
    fetchTotalPayout();
  }, []);
  return (
    <Container style={{ padding: '1rem', marginTop: 'calc(4% + 58px)' }}>
      <Paper
        style={{
          padding: '1rem',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-around',
           
        }}
      >
        <Grid style={{ display: 'flex', justifyContent: 'start' }}>
          <Grid>
            <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
              Total Payout
            </Typography>
            <Typography style={{ textAlign: 'center' }}>
              {totalTransactions}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'start' }}>
          <Grid>
            <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
              Today's Transacted
            </Typography>
            <Typography style={{ textAlign: 'center' }}>₹{todayTransacted}</Typography>
          </Grid>
        </Grid>
      </Paper>

      <Grid container style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5%', marginTop: '2%' }}>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '1rem', borderRadius: '10px', marginBottom: '4%',   }}>
            <Typography variant="h6" gutterBottom>
              7-Day Payout Summary
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ marginTop: '1rem' }}>
              Total Payout: ₹{totalPayout}
            </Typography>
          </Paper>

          <Paper style={{ padding: '1rem', borderRadius: '10px', marginBottom: '4%',   }}>
            <TextField
              label="From Date"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginBottom: '0.5rem' }}
            />
            <TextField
              label="To Date"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginBottom: '1rem' }}
            />
            <Button variant="contained" color="primary" onClick={handleSearch} style={{ marginRight: '0.5rem' }}>
              Search
            </Button>
            <Button variant="outlined" onClick={handleClear}>Clear</Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={7}>
          <Paper style={{ padding: '1rem', borderRadius: '10px', marginBottom: '4%',  }}>
            <Typography variant="h6" gutterBottom>7-Day Payout Chart</Typography>
            {data.length > 0 ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="payout" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Typography style={{ textAlign: 'center', marginTop: '1rem' }}>
                There is no transaction available on selected date
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: '2%' }}>
        <Typography variant="h6">Recent Transactions</Typography>
        <TextField
          label="Search by Transaction ID"
          variant="outlined"
          value={searchTransactionId}
          onChange={handleTransactionSearch}
          size="small"
          autoComplete='off'
        />
      </Grid>

      <Grid style={{ marginTop: '2%', marginBottom: '5%' }}>
        <TableContainer component={Paper} sx={{ borderRadius: '12px 12px 0px 0px' }}>
          <Table>
            <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
              <TableRow>
                <TableCell style={{ color: 'white' }}>Sr. No.</TableCell>
                <TableCell style={{ color: 'white' }}>Txn ID</TableCell>
                <TableCell style={{ color: 'white' }}>Utr No.</TableCell>
                <TableCell style={{ color: 'white' }}>Account No.</TableCell>
                <TableCell style={{ color: 'white' }}>Account Name</TableCell>
                <TableCell style={{ color: 'white' }}>Date</TableCell>
                <TableCell style={{ color: 'white' }}>Opening Balance</TableCell>
                <TableCell style={{ color: 'white' }}>Amount</TableCell>
                <TableCell style={{ color: 'white' }}>Closing Balance</TableCell>
                <TableCell style={{ color: 'white' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData.map((row, index) => (
                  <TableRow key={index} className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.txnReferenceId}</TableCell>
                    <TableCell>{row.poolReferenceId}</TableCell>
                    <TableCell>{row.payeeAccount}</TableCell>
                    <TableCell>{row.payeeName || 'N/A'}</TableCell>
                    <TableCell>{new Date(row.timestamp).toLocaleDateString()}</TableCell>
                    <TableCell>₹{parseFloat(row.walletOpeningBalance).toFixed(2)}</TableCell>
                    <TableCell>₹{parseFloat(row.txnValue).toFixed(2)}</TableCell>
                    <TableCell>₹{parseFloat(row.walletClosingBalance).toFixed(2)}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} style={{ textAlign: 'center' }}>
                    No transactions available for the selected date range.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalTransactions}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            nextIconButtonProps={{ disabled: !hasMore }} // Disable Next button if no more pages
          />
        </TableContainer>
      </Grid>

    </Container >
  );
};

export default CardandBarChart;