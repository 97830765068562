import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const QrCodeNotReady = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [gst, setGst] = useState("");
  const onApply = () => {
    handleOpen();
  };
  const handleApply = () => {
    handleClose();
    applyForQRonApply(name, description, gst);
  };
  const applyForQRonApply = async (name, description, gst) => {
    // console.log("onApply");
    // Validate Name
    const gstPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}$/;
    const nameRegex = /^[A-Za-z0-9@#\s\W]{3,100}$/;
    console.log(name, description, gst);
    if (!name || !nameRegex.test(name)) {
      Swal.fire({
        title: "Error While Apply",
        text: "Invalid name: Must be valid and between 3 and 100 characters.",
        icon: "warning",
      });
      return; // Or you can return a specific error message or display an alert
    }

    // Validate Description
    if (!description || !nameRegex.test(description)) {
      Swal.fire({
        title: "Error While Apply",
        text: "Invalid description: Must be valid and between 3 and 100 characters.",
        icon: "warning",
      });

      return; // Or you can return a specific error message or display an alert
    }
    console.log("this is gst", gst);
    // Validate GST
    if (gst && !gstPattern.test(gst)) {
      Swal.fire({
        title: "Error While Apply",
        text: "Invalid GST Number: Please Enter Valid GST Number.",
        icon: "warning",
      });

      return; // Or you can return a specific error message or display an alert
    }
    // If GST is not provided, set it to an empty string
    const gstin = gst || "";
    const jwtToken = localStorage.getItem("jwtToken");
    try {
      const response = await axios.post(
        "https://testapi.ipaisa.co.in/myapp/razorqr/create",
        {
          displayName: name,
          description: description,
          gstin: gstin,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      console.log(response.status);
      if (response.status === 200) {
        Swal.fire({
          title: "QR Request",
          text:
            "Hii..." +
            response.data.message +
            "  Your Request is Generated wait for Admin Acceptance!!",
          icon: "success",
          timer: 2000,
        });

        // toast.success(
        //   "Hii..." +
        //     response.data.label +
        //     "  Your Request is Generated wait for Admin Acceptance!!"
        // );
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: "Something Went Wrong!!",
        text: e.response.data.message,
        icon: "error",
        timer: 2000,
      });
      // toast.error(e.response.data.message);
    }
  };
  return (
    <>
      <h3>Apply For King QR Code</h3>
      <Container>
        <Paper sx={{ padding: 4 }}>
          <Stack spacing={6}>
            <Typography>
              Hit the Button to generate your personalized QR code. Once your
              application is processed, you'll receive a unique QR code that can
              be used
            </Typography>
            <Button variant="contained" onClick={onApply}>
              Apply
            </Button>
          </Stack>
        </Paper>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "1px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Stack spacing={1}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Fill Details to Apply
              </Typography>
              <TextField
                label="Enter Name for Your QR"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></TextField>
              <TextField
                label="Enter Description for QR"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></TextField>
              <TextField
                label="Enter GST No if Any"
                value={gst}
                onChange={(e) => setGst(e.target.value)}
              ></TextField>
              <Box display="flex" justifyContent="end">
                <Stack direction="row" spacing={1}>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" onClick={handleApply}>
                    Send Request
                  </LoadingButton>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default QrCodeNotReady;
