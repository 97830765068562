import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReportIcon from '@mui/icons-material/Report';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';  // Import useNavigate hook
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChangeCircleOutlined, CreateNewFolder, Delete, Download, HowToReg, NoteAddOutlined, QrCode, QrCode2 } from '@mui/icons-material';
import { DeleteUserIcon } from '../../res/NewWebIcons';
import { CreateTicket_Icon, Logout_Icon } from '../../res/icons';
import { TicketBlackIcon } from '../../res/retailer icons';
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
import { Grid, OutlinedInput } from '@material-ui/core';
import iPaisaLogo from "../Assets/login/iPaisaLogo2.png";


const drawerWidth = 250;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: open ? 0 : `-${drawerWidth}px`,
        transitionDuration: '0.3s',  // Optional: Adjust transition duration for smooth resizing
    })
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: 'white',
    color: 'black',
    boxShadow:'none',
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
    const theme = useTheme();
    const navigate = useNavigate();  // Initialize useNavigate hook
    const [open, setOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('Dashboard');
    const [expanded, setExpanded] = useState(null);  // Tracks the expanded accordion
    const [anchorEl, setAnchorEl] = useState(null); // For avatar menu
    const openMenu = Boolean(anchorEl); // To determine if menu is open
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screen
    const userType = localStorage.getItem("uType");
    const [selectedItem, setSelectedItem] = useState(null); // Track selected item

    const handleItemClick = (itemName) => {
        setSelectedItem(itemName);
        navigate('/dashboard')
        handleDrawerClose(); // Close the drawer

        // Your additional click logic here
    };
    const pdfFiles = {
        CHANNELPARTNER: 'AgreementCP.pdf',
        SUPERDISTRIBUTOR: 'AgreementSD.pdf',
        MASTERDISTRIBUTOR: 'AgreementMD.pdf',
        AREADISTRIBUTOR: 'AgreementAD.pdf',
        RETAILER: 'AgreementRetailer.pdf',
    };

    const handleDownloadAgreement = () => {
        const pdfUrl = pdfFiles[userType];

        if (pdfUrl) {
            // Create an anchor element and trigger the download
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = `${userType}_Agreement.pdf`; // Optional: set a custom filename
            link.click();
        } else {
            console.error('No PDF available for this user type');
        }
    };


    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget); // Set the menu anchor
    };

    const handleMenuClose = () => {
        setAnchorEl(null); // Close the menu
    };

    const handleDashboardClick = () => {
        navigate('/dashboardfinal'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleDeletedUsersClick = () => {
        navigate('/deletedusertable'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleCreateTicketClick = () => {
        navigate('/createticket'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handlePaymentReqClick = () => {
        navigate('/requestpaymentissue'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleChangeParentClick = () => {
        navigate('/changeparent'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleKycVerifyClick = () => {
        navigate('/kycverification'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleQueenQRClick = () => {
        navigate('/applyqr'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };
    const handleKingQRClick = () => {
        navigate('/razorqrreq'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };
    const handleAllQueenListClick = () => {
        navigate('/vlist'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };
    const handleAllKingListClick = () => {
        navigate('/allrazorqrlist'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
    };

    const handleLogout = () => {
        navigate('/SignIn'); // Navigate to '/dashboard' on click
        handleDrawerClose(); // Close the drawer
        localStorage.clear();
    };

    const handlePartnerClick = (partnerType) => {
        switch (partnerType) {
            case 'Channel Partner':
                navigate('/cplist');  // Navigate to '/cplist' when "Channel Partner" is clicked
                break;
            case 'Super Distributor':
                navigate('/sdlist'); // Navigate to appropriate route for Super Distributor
                break;
            case 'Master Distributor':
                navigate('/mdlist'); // Navigate to appropriate route for Master Distributor
                break;
            case 'Area Distributor':
                navigate('/adlist'); // Navigate to appropriate route for Area Distributor
                break;
            case 'Retailer':
                navigate('/retailer'); // Navigate to appropriate route for Retailer
                break;
            case 'Tech Support':
                navigate('/techsupport'); // Navigate to appropriate route for Tech Support
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };
    const handleBalanceClick = (balanceType) => {
        switch (balanceType) {
            case 'Fund Transfer':
                navigate('/fundtransfer');
                break;
            case 'Reverse Money':
                navigate('/reversemoney');
                break;
            case 'Direct FTR':
                navigate('/directftr');
                break;
            case 'Payout Checker':
                navigate('/payoutchecker');
                break;
            case 'Deeplink Client Reg':
                navigate('/DeepLinkClientReg');
                break;
            case 'Bank Charges':
                navigate('/bankcharges');
                break;
            case 'Card Charges':
                navigate('/cardcharges');
                break;
            case 'Add Money':
                navigate('/addmoney');
                break;
            case 'Payout':
                navigate('/payout');
                break;
            case 'Add Beneficiary':
                navigate('/addBeneficiary');
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };
    const handleReportClick = (reportType) => {
        switch (reportType) {
            case 'PayIn':
                navigate('/payinreport');
                break;
            case 'PayOut':
                navigate('/payoutreport');
                break;
            case 'CompletedTrans':
                navigate('/CompletedTransactions');
                break;
            case 'QR Transactions':
                navigate('/virtualtrans');
                break;
            case 'Credit Card Bill Payments':
                navigate('/ccbilllist');
                break;
            case 'Fund Transfer':
                navigate('/FundTransferReports');
                break;
            case 'Reverse Money':
                navigate('/ReverseMoneyReports');
                break;
            case 'Commission':
                navigate('/commissionreport');
                break;
            case 'Hierarchy Wise':
                navigate('/userdropdown');
                break;
            case 'Pending':
                navigate('/pendingreports');
                break;
            case 'Total Payouts':
                navigate('/TotalPayout');
                break;
            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };
    const handleSettingClick = (settingtype) => {
        switch (settingtype) {
            case 'IPin Setting':
                navigate('/mpinlanding');
                break;
            case 'General Setting':
                navigate('/generalsetting');
                break;
            case 'Blogs Updater':
                navigate('/blogsupdater');
                break;
            case 'Send SMS':
                navigate('/sendsms');
                break;

            default:
                break;
        }
        handleDrawerClose(); // Close the drawer after navigation
    };
    const handleQRCodeOpen = () => {
        handleMenuClose();
        navigate("/qrcode");
    };
    const handleRazorQRCodeOpen = () => {
        handleMenuClose();
        navigate("/razorpayqrcode");
    };
    const handleUserProfileView = () => {
        handleMenuClose();
        navigate("/userprofile");
    };

    const drawerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                handleDrawerClose();
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener when the component unmounts or open state changes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, handleDrawerClose]);
    const [uType, setUType] = useState(null);

    useEffect(() => {
        const userType = localStorage.getItem('uType');
        setUType(userType);
    }, []);

    // Only render the list if uType is 'admin'

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar style={{boxShadow:'none'}} position="fixed" open={open}>
                <Toolbar sx={{

                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Avatar
                            alt="Profile"
                            src={iPaisaLogo} // Replace with dynamic image source
                            sx={{ cursor: 'pointer', width: '50px', height: '50px' }}
                        />
                    <Typography variant="h6" noWrap component="div" sx={{paddingLeft:'0.5%', flexGrow: 1 }}>
                        iPaisa
                    </Typography>


                    {/* <Grid item>
                        <OutlinedInput
                            placeholder="Search..."
                            sx={{
                                width: '255px',
                                height: '50px',
                                opacity: 1,
                                borderRadius: '50%', // Rounded corners on the input itself
                                transition: 'opacity 0.3s ease-in-out',
                            }}
                        />
                    </Grid> */}
                    <Avatar
                        alt="KamranProfile"
                        src="/static/images/avatar/1.jpg" // Replace with dynamic image source
                        onClick={handleMenuOpen}
                        sx={{ cursor: 'pointer' }}
                    />
                    <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleUserProfileView}>My Profile</MenuItem>
                        <MenuItem onClick={handleQRCodeOpen}>Queen QR Code</MenuItem>
                        <MenuItem onClick={handleRazorQRCodeOpen}>King QR Code</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>

                    <Divider />
                    <Grid style={{display:'flex', justifyContent:'center'}}>
                        <Avatar
                            alt="Profile"
                            src={iPaisaLogo} // Replace with dynamic image source
                            sx={{ cursor: 'pointer', width: '50px', height: '50px' }}
                        />
                    </Grid>


                    </Menu>
                </Toolbar>
                <Divider/>
            </AppBar>
            <Drawer
                ref={drawerRef}
                sx={{
                    width: isMobile ? 250 : 0,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isMobile ? 250 : drawerWidth,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
                variant={isMobile ? "temporary" : "persistent"}
                anchor="left"
                open={open}
                onClose={handleDrawerClose}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider />
                <List>
                    <ListItemButton
                        onClick={() => handleItemClick('Dashboard')}
                        sx={{
                            bgcolor: selectedItem === 'Dashboard' ? 'primary.light' : 'transparent', // Selected color
                            ':hover': {
                                bgcolor: 'primary.main', // Hover color
                                color: 'white',
                            },
                            color: selectedItem === 'Dashboard' ? 'white' : 'inherit', // Text color on select
                        }}
                    >
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>

                    {/* Partner Section Accordion */}
                    <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid style={{alignItems:'center', display:'flex'}}>
                            <ListItemIcon>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primary="Partner" />
                        </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            {uType === 'ADMIN' && (
                                <>
                                    <ListItemButton onClick={() => handlePartnerClick('Channel Partner')}>
                                        <ListItemText primary="Channel Partner" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Super Distributor')}>
                                        <ListItemText primary="Super Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Master Distributor')}>
                                        <ListItemText primary="Master Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Area Distributor')}>
                                        <ListItemText primary="Area Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                        <ListItemText primary="Retailer" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Tech Support')}>
                                        <ListItemText primary="Tech Support" />
                                    </ListItemButton>
                                </>
                            )}
                            {/* Channel Partner: Show Super Distributor, Master Distributor, Area Distributor, Retailer */}
                            {uType === 'CHANNELPARTNER' && (
                                <>
                                    <ListItemButton onClick={() => handlePartnerClick('Super Distributor')}>
                                        <ListItemText primary="Super Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Master Distributor')}>
                                        <ListItemText primary="Master Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Area Distributor')}>
                                        <ListItemText primary="Area Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                        <ListItemText primary="Retailer" />
                                    </ListItemButton>
                                </>
                            )}

                            {/* Super Distributor: Show Master Distributor, Area Distributor, Retailer */}
                            {uType === 'SUPERDISTRIBUTOR' && (
                                <>
                                    <ListItemButton onClick={() => handlePartnerClick('Master Distributor')}>
                                        <ListItemText primary="Master Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Area Distributor')}>
                                        <ListItemText primary="Area Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                        <ListItemText primary="Retailer" />
                                    </ListItemButton>
                                </>
                            )}

                            {/* Master Distributor: Show Area Distributor, Retailer */}
                            {uType === 'MASTERDISTRIBUTOR' && (
                                <>
                                    <ListItemButton onClick={() => handlePartnerClick('Area Distributor')}>
                                        <ListItemText primary="Area Distributor" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                        <ListItemText primary="Retailer" />
                                    </ListItemButton>
                                </>
                            )}

                            {/* Area Distributor: Show Retailer */}
                            {uType === 'AREADISTRIBUTOR' && (
                                <ListItemButton onClick={() => handlePartnerClick('Retailer')}>
                                    <ListItemText primary="Retailer" />
                                </ListItemButton>
                            )}
                        </AccordionDetails>

                    </Accordion>

                    {/* Balance Section Accordion */}

                    <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid style={{alignItems:'center', display:'flex'}}>
                            <ListItemIcon>
                                <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText primary="Balance" />
                        </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton onClick={() => handleBalanceClick('Fund Transfer')}>
                                <ListItemText primary="Fund Transfer" />
                            </ListItemButton>
                            <ListItemButton onClick={() => handleBalanceClick('Add Beneficiary')}>
                                <ListItemText primary="Add Beneficiary" />
                            </ListItemButton>

                            {/* Conditionally render based on userType */}
                            {userType === 'ADMIN' && (
                                <>
                                    <ListItemButton onClick={() => handleBalanceClick('Reverse Money')}>
                                        <ListItemText primary="Reverse Money" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleBalanceClick('Direct FTR')}>
                                        <ListItemText primary="Direct FTR" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleBalanceClick('Payout Checker')}>
                                        <ListItemText primary="Payout Checker" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleBalanceClick('Deeplink Client Reg')}>
                                        <ListItemText primary="Deeplink Client Reg" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleBalanceClick('Bank Charges')}>
                                        <ListItemText primary="Bank Charges" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleBalanceClick('Card Charges')}>
                                        <ListItemText primary="Card Charges" />
                                    </ListItemButton>
                                </>
                            )}

                            {(userType === 'AREADISTRIBUTOR' || userType === 'RETAILER') && (
                                <>
                                    <ListItemButton onClick={() => handleBalanceClick('Add Money')}>
                                        <ListItemText primary="Add Money" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleBalanceClick('Payout')}>
                                        <ListItemText primary="Payout" />
                                    </ListItemButton>
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>



                    {/* Report Section Accordion */}
                    <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid style={{alignItems:'center', display:'flex'}}>
                            <ListItemIcon>
                                <ReportIcon />
                            </ListItemIcon>
                            <ListItemText primary="Reports" />
                        </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton onClick={() => handleReportClick('Fund Transfer')}>
                                <ListItemText primary="Fund Transfer Report" />
                            </ListItemButton>

                            {/* Conditionally render based on userType */}
                            {userType === 'ADMIN' && (
                                <>
                                    <ListItemButton onClick={() => handleReportClick('CompletedTrans')}>
                                        <ListItemText primary="Completed Trans Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick('QR Transactions')}>
                                        <ListItemText primary="QR Transactions Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick('Credit Card Bill Payments')}>
                                        <ListItemText primary="Credit Card Bill Payments Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick('Reverse Money')}>
                                        <ListItemText primary="Reverse Money Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick('Commission')}>
                                        <ListItemText primary="Commission Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick('Hierarchy Wise')}>
                                        <ListItemText primary="Hierarchy Wise Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick('Pending')}>
                                        <ListItemText primary="Pending Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick('Total Payouts')}>
                                        <ListItemText primary="Total Payouts" />
                                    </ListItemButton>
                                </>
                            )}

                            {(userType === 'AREADISTRIBUTOR' || userType === 'RETAILER') && (
                                <>
                                    <ListItemButton onClick={() => handleReportClick('PayIn')}>
                                        <ListItemText primary="PayIn Report" />
                                    </ListItemButton>
                                    <ListItemButton onClick={() => handleReportClick('PayOut')}>
                                        <ListItemText primary="Payout Report" />
                                    </ListItemButton>

                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>
                    <ListItemButton onClick={handleDownloadAgreement}>
                        <ListItemIcon>
                            <Download />
                        </ListItemIcon>
                        <ListItemText primary="Download Agreement" />
                    </ListItemButton>
                    <ListItemButton onClick={handleKycVerifyClick}>
                        <ListItemIcon>
                            <HowToReg />
                        </ListItemIcon>
                        <ListItemText primary="KYC Verfication" />
                    </ListItemButton>
                    {userType === 'ADMIN' && (
                        <>
                            <ListItemButton onClick={handleChangeParentClick}>
                                <ListItemIcon>
                                    <ChangeCircleOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Change Parent" />
                            </ListItemButton>

                            <ListItemButton onClick={handleQueenQRClick}>
                                <ListItemIcon>
                                    <QrCode2 />
                                </ListItemIcon>
                                <ListItemText primary="Queen QR Requests" />
                            </ListItemButton>

                            <ListItemButton onClick={handleKingQRClick}>
                                <ListItemIcon>
                                    <QrCode />
                                </ListItemIcon>
                                <ListItemText primary="King QR Requests" />
                            </ListItemButton>

                            <ListItemButton onClick={handleAllQueenListClick}>
                                <ListItemIcon>
                                    <QrCode2 />
                                </ListItemIcon>
                                <ListItemText primary="All Queen QR List" />
                            </ListItemButton>

                            <ListItemButton onClick={handleAllKingListClick}>
                                <ListItemIcon>
                                    <QrCode />
                                </ListItemIcon>
                                <ListItemText primary="All King QR List" />
                            </ListItemButton>

                            <ListItemButton onClick={handleDeletedUsersClick}>
                                <ListItemIcon>
                                    <Delete />
                                </ListItemIcon>
                                <ListItemText primary="Deleted Users" />
                            </ListItemButton>
                        </>
                    )}
                    <ListItemButton onClick={handleCreateTicketClick}>
                        <ListItemIcon>
                            <CreateNewFolder />
                        </ListItemIcon>
                        <ListItemText primary="Create Ticket" />
                    </ListItemButton>
                    <ListItemButton onClick={handlePaymentReqClick}>
                        <ListItemIcon>
                            <NoteOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Payment Request" />
                    </ListItemButton>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid style={{alignItems:'center', display:'flex'}}>
                            <ListItemIcon>
                                <ReportIcon />
                            </ListItemIcon>
                            <ListItemText primary="Settings" />
                        </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton onClick={() => handleReportClick('IPin Setting')}>
                                <ListItemText primary="IPin Setting" />
                            </ListItemButton>
                            <ListItemButton onClick={() => handleReportClick('General Setting')}>
                                <ListItemText primary="General Setting" />
                            </ListItemButton>
                            <ListItemButton onClick={() => handleReportClick('Blogs Updater')}>
                                <ListItemText primary="Blogs Updater" />
                            </ListItemButton>
                            <ListItemButton onClick={() => handleReportClick('Send SMS')}>
                                <ListItemText primary="Send SMS" />
                            </ListItemButton>

                        </AccordionDetails>
                    </Accordion>
                    <ListItemButton
                        onClick={handleLogout} // Define handleLogout to log out the user
                        sx={{
                            mt: 1, // Top margin for separation
                            mb: 2, // Optional bottom margin for extra spacing
                            bgcolor: 'error.main', // Optional: background color to highlight the logout button
                            color: 'white', // White text for contrast
                            ':hover': {
                                bgcolor: 'error.dark', // Darker color on hover
                            },
                        }}
                    >
                        <ListItemIcon>
                            <Logout_Icon sx={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </List>
                <Box sx={{ flexGrow: 1 }} />

                {/* Logout button styled separately at the bottom */}

            </Drawer>
        </Box>
    );
}

