import React, { useState, useEffect } from 'react';
import {
    Button, Container, Grid, CircularProgress, TableContainer, Table, TableHead,
    TableRow, TableCell, TableBody, Paper, TablePagination, IconButton,
    TextField,
    Box,
    Typography,
} from '@mui/material';
import DownloadIcon1 from "@mui/icons-material/Download";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import axios from 'axios';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import CompletedTransInvoice from './CompletedTransInvoice';


const CompeletedTrans = () => {
    const [completeData, setCompleteData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        fetchCompleteData(page, fromDate, toDate);
    }, [page]);

    const fetchCompleteData = async (currentPage = 0, startDate = '', endDate = '') => {
        setLoading(true);
        const jwtToken = localStorage.getItem('jwtToken');
        try {
            const response = await axios.get(`https://testapi.ipaisa.co.in/myapp/admin/payinCompletedTxns`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
                params: {
                    page: currentPage,
                    size: rowsPerPage,
                    ...(startDate && { fromDate: startDate }),
                    ...(endDate && { toDate: endDate })
                }
            });
            setCompleteData(response.data.body.data || []);
        } catch (error) {
            console.error('Error fetching Complete data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        setPage(0); // Reset to the first page on new search
        fetchCompleteData(0, fromDate, toDate); // Pass date parameters when search is triggered
    };

    const handlePageChange = (event, newPage) => setPage(newPage);
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownloadInvoice = async (data) => {
        const receiptElement = document.getElementById(`receipt-${data.txnid}`);

        if (!receiptElement) {
            console.error('Receipt element not found:', `receipt-${data.txnid}`);
            return;
        }

        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
            filename: `CompletedTransactions Receipt-${data.txnid}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
        };

        try {
            await html2pdf().from(receiptElement).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF: ', error);
        }
    };
    
    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Reports</Typography>
                    <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Completed Transactions Report</Typography>
            </Grid>
                <Grid style={{marginTop:'1%'}}>
                    <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Completed Transactions Report</Typography>
                </Grid>
            <Paper elevation={2} style={{ padding: '2rem', borderRadius: '8px', marginTop: '2%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="From Date"
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="To Date"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '3%' }}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                            <Button
                                onClick={handleSearch}
                                sx={{
                                    backgroundColor: '#027BFE',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: '120px',
                                }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => {
                                    setFromDate('');
                                    setToDate('');
                                    setPage(0);
                                    fetchCompleteData(0, '', ''); // Reset data
                                }}
                                sx={{
                                    backgroundColor: 'gray',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: '120px',
                                }}
                            >
                                Clear
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <TableContainer component={Paper} sx={{ mt: 3, borderRadius: 2 }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#027BFE' }}>
                                <TableRow>
                                    {['Sr. No.', 'Txn ID', 'EasePay ID','UserID', 'Payment Type', 'Payment By',, 'Date','PayIn Amount', 'Transfer Amount', 'Status', 'Settlement', 'Invoice'].map(header => (
                                        <TableCell align="start" key={header} sx={{ color: 'white' }}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {completeData.length > 0 ? completeData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                        <TableCell>{row.txnid|| 'N/A'}</TableCell>
                                        <TableCell>{row.easepayid || 'N/A'}</TableCell>
                                        <TableCell>{row.userId || 'N/A'}</TableCell>
                                        <TableCell>{row.cardType}</TableCell>
                                        {/* Conditional rendering for "Payment By" based on cardType */}
                                        {row.cardType === 'UPI' ? (
                                        <TableCell>{row.upiVa}</TableCell>  // Payment By UPI
                                        ) : row.cardType === 'Credit Card' || row.cardType === 'Debit Card' ? (
                                        <TableCell>{row.cardnum}</TableCell>  // Payment By Card Number
                                        ) : (
                                        <TableCell>N/A</TableCell>  // Handle other card types if necessary
                                        )}      
                                        <TableCell>{row.addedon ? new Date(row.addedon).toLocaleString() : 'N/A'}</TableCell>
                                        <TableCell>{row.netAmountDebit || 'N/A'}</TableCell>
                                        <TableCell>{row.amount || 'N/A'}</TableCell>
                                        {/* <TableCell>{row.status || 'N/A'}</TableCell> */}
                                        <TableCell
                                            align="start"
                                            style={{
                                                color: row.status.toLowerCase() === 'success'
                                                    ? 'green'
                                                    : row.status.toLowerCase() === 'refund'
                                                    ? 'ad'
                                                    : row.status.toLowerCase() === 'pending'
                                                    ? 'orange'
                                                    : 'red',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {row.status.toLowerCase() === 'success'
                                                ? 'Successful'
                                                : row.status.toLowerCase() === 'refund'
                                                ? 'Refund'
                                                : row.status.toLowerCase() === 'pending'
                                                ? 'Pending'
                                                : 'Failed'}
                                        </TableCell>
                                        <TableCell>{row.settlement || 'N/A'}</TableCell>
                                        <TableCell align="center">
                                            <DownloadIcon1
                                                onClick={() => handleDownloadInvoice(row)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </TableCell>
                                        <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                            <Grid id={`receipt-${row.txnid}`}>
                                                <CompletedTransInvoice data={row} />
                                            </Grid>
                                        </Grid>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={11} align="center">No Data Available</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={completeData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        backIconButtonProps={{
                            disabled: page === 0,
                            children: <NavigateBeforeIcon />
                        }}
                        nextIconButtonProps={{
                            disabled: completeData.length < rowsPerPage,
                            children: <NavigateNextIcon />
                        }}
                    />
                </>
            )}
        </Container>
    );
}

export default CompeletedTrans
