import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Autocomplete,
  FormControl,
  InputLabel,
  Switch,
  Select,
  MenuItem,
  TableHead,
  TableContainer,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PartnerArrowIcon } from '../../res/NewWebIcons';

const PayoutChecker = () => {
  const [userType, setUserType] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleUserTypeChange = async (event) => {
    const selectedUserType = event.target.value;
    setUserType(selectedUserType);

    try {
      const token = localStorage.getItem('jwtToken');
      const response = await fetch(`https://testapi.ipaisa.co.in/myapp/admin/get-users-by-type/${selectedUserType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status === 200) {
        const formattedUsers = data.users.map((user) => ({
          userid: user.userid,
          fullName: `${user.firstName} ${user.lastName}`,
          status: user.status,
          walletBalance: user.walletBalance,
          createdAt: user.createdAt,
          payoutStatus: user.payoutStatus,
        }));
        setUsers(formattedUsers);
      } else {
        console.error('Failed to fetch users:', data.message);
        setUsers([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    }
  };

  const handleToggleStatus = async (userId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await fetch(`https://testapi.ipaisa.co.in/myapp/admin/change-payout-status/${userId}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();

      if (data.status === 'status change successfully') {
        toast.success('Payout status updated successfully');
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.userid === userId
              ? { ...user, payoutStatus: user.payoutStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' }
              : user
          )
        );
      } else {
        toast.error('Failed to change payout status');
        console.error('Failed to change payout status:', data.message);
      }
    } catch (error) {
      toast.error('Error changing payout status');
      console.error('Error changing payout status:', error);
    }
  };

  const handleClear = () => {
    setSearchQuery('');
    setSelectedUser(null);
  };

  return (
    <Container style={{ padding: '1rem', marginTop: 'calc(4% + 58px)' }}>
      <ToastContainer />
      {/* Header Section */}
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color="textSecondary" style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>
          Balance
        </Typography>
        <PartnerArrowIcon />
        <Typography color="textSecondary" style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>
          Payout Checker
        </Typography>
      </Grid>
      <Grid style={{ marginTop: '1%' }}>
        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
          Payout Checker
        </Typography>
      </Grid>

      {/* Form and Table Section */}
      <Paper elevation={2} style={{ padding: '2rem', marginTop: '2%', borderRadius: '10px' }}>
        <Grid container spacing={2}>
          {/* User Type Dropdown */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>User Type</InputLabel>
              <Select label="User Type" value={userType} onChange={handleUserTypeChange}>
                <MenuItem value="AREADISTRIBUTOR">Area Distributor</MenuItem>
                <MenuItem value="RETAILER">Retailer</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Users Autocomplete */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={users}
              getOptionLabel={(option) => option.fullName || ''}
              value={selectedUser}
              onChange={(event, newValue) => setSelectedUser(newValue)}
              renderInput={(params) => <TextField {...params} label="Users" variant="outlined" />}
              fullWidth
            />
          </Grid>

          {/* Search Field */}
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Grid>

          {/* Search and Clear Buttons */}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
            <Button
              sx={{
                backgroundColor: 'gray',
                color: 'white',
                textTransform: 'none',
                width: { xs: '100%', md: '14%' },
                fontSize: '16px',
                borderRadius: '8px',
                height: '56px',
              }}
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              sx={{
                backgroundColor: '#027BFE',
                color: 'white',
                textTransform: 'none',
                width: { xs: '100%', md: '14%' },
                fontSize: '16px',
                borderRadius: '8px',
                height: '56px',
                '&:hover': {
                  backgroundColor: '#027BFE',
                  color: '#FFFFFF',
                },
              }}
              onClick={handleUserTypeChange}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3} style={{ marginTop: '1%' }}>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ borderRadius: '12px 12px 0px 0px' }}>
            <Table>
              {/* Table Head */}
              <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                <TableRow>
                  <TableCell style={{ color: 'white' }} align="start">User ID</TableCell>
                  <TableCell style={{ color: 'white' }} align="start">Full Name</TableCell>
                  <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                  <TableCell style={{ color: 'white' }} align="start">Wallet Balance</TableCell>
                  <TableCell style={{ color: 'white' }} align="start">Created At</TableCell>
                  <TableCell style={{ color: 'white' }} align="center">Action</TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user.userid}
                    sx={{
                      backgroundColor: user.userid % 2 === 0 ? '#f9f9f9' : '#ffffff', // Alternate row color
                    }}
                  >
                    <TableCell>{user.userid}</TableCell>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell>{user.status}</TableCell>
                    <TableCell>{Number(user.walletBalance).toFixed(2)}</TableCell>
                    <TableCell>{new Date(user.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell align="center">
                      <Switch
                        checked={user.payoutStatus === 'ACTIVE'}
                        onChange={() => handleToggleStatus(user.userid)}
                        sx={{
                          '& .MuiSwitch-switchBase.Mui-checked': { color: 'green' },
                          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: 'green' },
                          '& .MuiSwitch-switchBase:not(.Mui-checked)': { color: 'red' },
                          '& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track': { backgroundColor: 'red' },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

    </Container>
  );
};

export default PayoutChecker;
