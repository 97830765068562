import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import DownloadIcon1 from "@mui/icons-material/Download";
import { Grid } from '@material-ui/core';
import HiePayInInvoice from './HiePayInInvoice';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import HiePayoutInvoice from './HiePayoutInvoice';


const TransactionTable = ({ transactionType, userRecords }) => {

  // Payin Invoice
  const handleDownloadPayinInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.txnid}`);

    if (!receiptElement) {
        console.error('Receipt element not found:', `receipt-${data.txnid}`);
        return;
    }

    const opt = {
        margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
        filename: `Hierarchy Payin Receipt-${data.txnid}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
    };

    try {
        await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
        console.error('Error generating PDF: ', error);
    }
};

  // Payout Invoice
  const handleDownloadPayoutInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.externalRef}`);

    if (!receiptElement) {
        console.error('Receipt element not found:', `receipt-${data.externalRef}`);
        return;
    }

    const opt = {
        margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
        filename: `Hierarchy Payout Receipt-${data.externalRef}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
    };

    try {
        await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
        console.error('Error generating PDF: ', error);
    }
};


  return (
    <TableContainer component={Paper} sx={{ marginTop: '2%' }}>
      <Table>
        <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
          <TableRow>
            {/* Common Columns */}
            <TableCell style={{ color: 'white' }} align="start">Sr. No</TableCell>
            {transactionType === 'Pay-In' ? (
              <>
                <TableCell style={{ color: 'white' }} align="start">Transaction ID</TableCell>
                <TableCell style={{ color: 'white' }} align="start">User ID</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Phone</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Mode</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Date</TableCell>
                {/* <TableCell style={{ color: 'white' }} align="start">Error Message</TableCell> */}
                <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Amount</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Settlement</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Invoice</TableCell>

              </>
            ) : (
              <>
                <TableCell style={{ color: 'white' }} align="start">External Ref</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Payee Account</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Payee Name</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Date</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Order ID</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Wallet Opening Balance</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Amount</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Wallet Closing Balance</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Transfer Mode</TableCell>
                <TableCell style={{ color: 'white' }} align="start">Invoice</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {userRecords.length > 0 ? (
            userRecords.map((record, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                {transactionType === 'Pay-In' ? (
                  <>
                    <TableCell >{record.txnid || 'N/A'}</TableCell>
                    <TableCell>{record.userId || 'N/A'}</TableCell>
                    <TableCell>{record.phone || 'N/A'}</TableCell>
                    <TableCell>{record.mode || 'N/A'}</TableCell>
                    <TableCell>{new Date(record.addedon || 'N/A').toLocaleString()}</TableCell>
                    <TableCell
                      style={{
                          color: record.status === 'success' ? 'green' :
                                record.status === 'failure' ? 'red' :
                                record.status === 'Failed' ? 'red' :
                                record.status === 'preinitiated' ? 'darkgoldenrod' :
                                record.status === 'dropped' ? 'blue' : 'inherit',
                          fontWeight:'bold',
                          textTransform: 'capitalize'
                      }}
                  >
                      {record.status || 'N/A'}
                  </TableCell>                    
                  <TableCell>{Number(record.amount || 'N/A').toFixed(2)}</TableCell>
                  <TableCell>{record.settlement || 'N/A'}</TableCell>
                  <TableCell align="center">
                        <DownloadIcon1
                          onClick={() => handleDownloadPayinInvoice(record)}
                          style={{ cursor: 'pointer' }}
                        />
                  </TableCell>
                  <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                    <Grid id={`receipt-${record.txnid}`}>
                      <HiePayInInvoice data={record} />
                    </Grid>
                  </Grid>
                  
                  </>
                ) : (
                  <>
                    <TableCell>{record.externalRef || 'N/A'}</TableCell>
                    <TableCell>{record.payeeAccount || 'N/A'}</TableCell>
                    <TableCell>{record.payeeName || 'N/A'}</TableCell>
                    <TableCell>{new Date(record.timestamp || 'N/A').toLocaleString()}</TableCell>
                    <TableCell>{record.orderid || 'N/A'}</TableCell>
                    <TableCell>{Number(record.walletOpeningBalance || 'N/A').toFixed(2)}</TableCell>
                    <TableCell>{record.txnValue || 'N/A'}</TableCell>
                    <TableCell>{Number(record.walletClosingBalance || 'N/A').toFixed(2)}</TableCell>
                    <TableCell 
                        style={{
                          color: record.status === 'Transaction Successful' ? 'green' :
                                record.status === 'Transaction Pending' ? 'orange' :
                                record.status === 'REFUND' ? 'darkgoldenrod' :
                                record.status === 'Refund' ? 'darkgoldenrod' :
                                record.status === 'Transaction Failed' ? 'red' : 'inherit',
                          fontWeight:'bold',
                          textTransform: 'capitalize'
                      }}
                    >
                        {record.status === 'Transaction Successful' ? 'Successful'
                            : record.status === 'Transaction Pending' ? 'Pending'
                                : record.status === 'REFUND' ? 'Refund'
                                    : record.status === 'Transaction Failed' ? 'Failed'
                                        : record.status || 'N/A'} {/* Default value if no match */}
                    </TableCell>
                    <TableCell>{record.transfer_mode || 'N/A'}</TableCell>
                    <TableCell align="center">
                          <DownloadIcon1
                            onClick={() => handleDownloadPayoutInvoice(record)}
                            style={{ cursor: 'pointer' }}
                          />
                    </TableCell>
                    <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                      <Grid id={`receipt-${record.externalRef}`}>
                        <HiePayoutInvoice data={record} />
                      </Grid>
                    </Grid>
                  </>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={11} align="center">
                No Records Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionTable;
