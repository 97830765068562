import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Alert, Typography, Autocomplete } from '@mui/material';
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import BalanceTopComponent from './BalanceTopComponent';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import ReverseMoneySucc from './ReverseMoneySucc';
import ReverseMoneyFail from './ReverseMoneyFail';
import SuccessSound1 from '../Assets/SuccessSounds/SuccessSound1.mp3';
import SuccessSound2 from '../Assets/SuccessSounds/SuccessSound2.mp3';


const NewReverseMoney = () => {
    const [formData, setFormData] = useState({
        receiverId: "",
        receiverName: "",
        receiverType: "",
        amount: "",
        walletBalance: "", // User's wallet balance
        previousBalance: "", // Example previous balance
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [reverseMoneySucc, setReverseMoneySucc] = useState(false);
    const [reverseMoneyFail, setReverseMoneyFail] = useState(false);
    const [error, setError] = useState("");
    const [receiverOptions, setReceiverOptions] = useState([]); // For storing receiver options
    const audioRef = useRef(null); // Audio instance reference
    const [isAudioPlaying, setIsAudioPlaying] = useState(false); // Track audio playing status


    const handleClose = () => {
        setReverseMoneySucc(false);
        setReverseMoneyFail(false);

        // Stop the success sound when the popup is closed
        // if (audioRef.current) {
        //     audioRef.current.pause();
        //     audioRef.current.currentTime = 0; // Reset the audio
        // }
    };


    // Fetch wallet balance when the component is mounted
    useEffect(() => {
        const fetchWalletBalance = async () => {
            try {
                const token = localStorage.getItem("jwtToken");
                const response = await axios.get(
                    "https://testapi.ipaisa.co.in/myapp/auth/getwalletbalance",
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setFormData((prevData) => ({
                    ...prevData,
                    walletBalance: response.data.walletBalance,
                }));
            } catch (error) {
                console.error("Error fetching wallet balance:", error);
                setError("Failed to fetch wallet balance. Please try again.");
            }
        };

        fetchWalletBalance();
    }, []);

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'receiverType') {
            // Trigger API call when receiverType changes
            try {
                const token = localStorage.getItem('jwtToken');
                const response = await axios.get(
                    `https://testapi.ipaisa.co.in/myapp/admin/getAllPartner?userrole=${value}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // Set the received user data to receiverOptions
                const options = response.data.map((user) => ({
                    userId: user.userId,
                    name: user.Name
                }));
                setReceiverOptions(options); // Store options to map to dropdown

            } catch (error) {
                console.error('Error fetching partner data:', error);
            }
        }

        if (name === 'receiverId') {
            // Fetch selected user's details
            try {
                const token = localStorage.getItem('jwtToken');
                const selectedUserId = value;
                const userResponse = await axios.get(
                    `https://testapi.ipaisa.co.in/myapp/admin/getUserById?userId=${selectedUserId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // Update fields with user details including their wallet balance
                setFormData((prevData) => ({
                    ...prevData,
                    receiverId: selectedUserId,
                    receiverName: `${userResponse.data.firstName} ${userResponse.data.lastName}`,
                    previousBalance: userResponse.data.walletBalance // Map user's wallet balance to Previous Balance
                }));

            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        }

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = localStorage.getItem('jwtToken');
            // Submit the reverse money request
            const response = await axios.post(
                "https://testapi.ipaisa.co.in/myapp/admin/reversebalance",
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("Response:", response.data);
            setReverseMoneySucc(true);
            setReverseMoneyFail(false);
            setSuccess(true);
            setError(""); // Clear previous errors

            // const audio = new Audio(SuccessSound1);
            // audioRef.current = audio; // Store the audio instance
            // audio.play();

            // Fetch the updated Admin Wallet Balance after successful reversal
            const balanceResponse = await axios.get(
                "https://testapi.ipaisa.co.in/myapp/auth/getwalletbalance",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Update the wallet balance in formData with the new balance
            setFormData((prevData) => ({
                ...prevData,
                walletBalance: balanceResponse.data.walletBalance, // Update Admin Wallet Balance
                receiverId: "",
                receiverName: "",
                receiverType: "",
                amount: "",
                previousBalance: "", // Reset user's previous balance or update as needed
            }));
        } catch (error) {
            console.error("There was an error reversing the balance!", error);
            setError("Failed to reverse the balance. Please try again.");
            setReverseMoneyFail(true);
            setReverseMoneySucc(false);
        } finally {
            setLoading(false);
        }
    };


    const lineHeight = '50px';

    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid>
                <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                    <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>Balance</Typography>
                    <PartnerArrowIcon />
                    <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '13px' }}>Reverse Money</Typography>
                </Grid>

                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
                    <Grid>
                        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Reverse Money</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid spacing={2} style={{ marginTop: '3%' }}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <Paper style={{ padding: '2rem', borderRadius: '8px' }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
                                    <FormControl fullWidth required>
                                        <InputLabel>Receiver Type</InputLabel>
                                        <Select
                                            name="receiverType"
                                            value={formData.receiverType}
                                            onChange={handleInputChange}
                                            label="Receiver Type"
                                        >
                                            <MenuItem value="CHANNELPARTNER">Channel Partner</MenuItem>
                                            <MenuItem value="SUPERDISTRIBUTOR">Super Distributor</MenuItem>
                                            <MenuItem value="MASTERDISTRIBUTOR">Master Distributor</MenuItem>
                                            <MenuItem value="AREADISTRIBUTOR">Area Distributor</MenuItem>
                                            <MenuItem value="RETAILER">Retailer</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            options={receiverOptions}
                                            getOptionLabel={(option) => `${option.userId} - ${option.name}`}
                                            value={receiverOptions.find(option => option.userId === formData.receiverId) || null}
                                            onChange={(event, newValue) => {
                                                handleInputChange({
                                                    target: {
                                                        name: 'receiverId',
                                                        value: newValue ? newValue.userId : ''
                                                    }
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Receiver ID" variant="outlined" required />
                                            )}
                                            isOptionEqualToValue={(option, value) => option.userId === value}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
                                    <TextField
                                        label="Receiver Name / Business Name"
                                        name="receiverName"
                                        value={formData.receiverName}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
                                    <TextField
                                        label="Amount"
                                        name="amount"
                                        type="number"
                                        value={formData.amount}
                                        onChange={handleInputChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
                                    <TextField
                                        label="User Wallet Balance"
                                        value={Number(formData.previousBalance).toFixed(2)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ marginTop: { xs: '0%', md: '1.5%' } }}>
                                    <TextField
                                        label="Admin Wallet Balance"
                                        value={Number(formData.walletBalance).toFixed(2)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: { xs: '0%', md: '3%' } }}>
                                <Box display="flex" justifyContent="end" gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                    <Button
                                        sx={{
                                            border: '1.5px solid #FF0000',
                                            height: lineHeight,
                                            fontSize: '16px',
                                            borderRadius: '8px',
                                            color: '#FF0000',
                                            textTransform: 'none',
                                            width: { xs: '100%', md: '18%' },
                                        }}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        sx={{
                                            height: lineHeight,
                                            fontSize: '16px',
                                            textTransform: 'none',
                                            width: { xs: '100%', md: '18%' },
                                            borderRadius: '8px',
                                            backgroundColor: "#027BFE",
                                            color: '#FFFFFF',
                                            '&:hover': {
                                                backgroundColor: "#027BFE",
                                                color: '#FFFFFF',
                                            },
                                        }}
                                        disabled={loading}
                                    >
                                        {loading ? "Reversing..." : "Reverse Money"}
                                    </Button>
                                </Box>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>

            {reverseMoneySucc && <ReverseMoneySucc handleClose={handleClose} />}
            {reverseMoneyFail && <ReverseMoneyFail handleClose={handleClose} />}

        </Container>
    );
};

export default NewReverseMoney;
