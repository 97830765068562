import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Modal,
    Box,
    Typography,
    IconButton,
    TablePagination,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));

const DirectFTR = () => {
    const [open, setOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 15 });
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noTransactions, setNoTransactions] = useState(false);
    const [page, setPage] = useState(0); // Current page state
    const [size, setSize] = useState(10); // Page size state
    const classes = useStyles();

    const token = localStorage.getItem('jwtToken');

    const fetchTransactions = async (page, size) => {
        try {
            const response = await axios.get(`https://testapi.ipaisa.co.in/myapp/admin/easePendingTxns?page=${page}&size=${size}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.statusCodeValue === 204) {
                setNoTransactions(true);
                setLoading(false);
                return;
            }

            if (Array.isArray(response.data.body)) {
                setTransactions(response.data.body);
                setNoTransactions(false); // Reset the noTransactions state
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        fetchTransactions(page, size); // Call fetchTransactions with page and size
    }, [page, size, token]);

    const handlePay = async () => {
        if (selectedTransaction) {
            const { txnid, userId, amount } = selectedTransaction;

            try {
                const response = await axios.post('https://testapi.ipaisa.co.in/myapp/admin/payinSettelment', {
                    transactionId: txnid,
                    userId,
                    amount,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    toast.success(response.data.body.message); // Show success toast
                    await fetchTransactions(page, size); // Refresh transactions
                } else {
                    toast.error('Payment failed!'); // Show error toast
                }

                setOpen(false);
            } catch (error) {
                console.error('Error processing payment:', error);
                toast.error('Error processing payment. Please try again.'); // Show error toast
            }
        }
    };

    const handleOpenModal = (event, transaction) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setModalPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX + rect.width });
        setSelectedTransaction(transaction);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleComplete = async (transaction) => {
        try {
            const response = await axios.post(
                'https://testapi.ipaisa.co.in/myapp/admin/settelemtComplete',
                { txnId: transaction.txnid },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success(response.data.body.message); // Show success toast
                await fetchTransactions(page, size); // Refresh transactions
            } else {
                toast.error('Settlement update failed!');
            }
        } catch (error) {
            console.error('Error completing settlement:', error);
            toast.error('Error completing settlement. Please try again.');
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage); // Update page
    };

    const handleNextIconClick = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePrevIconClick = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const isNextDisabled = transactions.length < size;

    return (
        <Container style={{ padding: '1rem', marginTop: 'calc(4% + 58px)' }}>  
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Reports</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Direct FTR</Typography>
            </Grid>
            <Grid style={{marginTop:'1%'}}>
                <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Direct FTR</Typography>
            </Grid>
            <Grid container spacing={3} style={{marginTop:'1%'}}>
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ borderRadius: '12px 12px 0px 0px' }}>
                        <Table>
                            <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }} align="start">Sr. No</TableCell>
                                    <TableCell style={{ color: 'white' }} align="start">Txn ID</TableCell>
                                    <TableCell style={{ color: 'white' }} align="start">User ID</TableCell>
                                    <TableCell style={{ color: 'white' }} align="start">Date</TableCell>
                                    <TableCell style={{ color: 'white' }} align="start">PayIn Amount</TableCell>
                                    <TableCell style={{ color: 'white' }} align="start">Transfer Amount</TableCell>
                                    <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                                    <TableCell style={{ color: 'white' }} align="start">Settlement</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">Loading...</TableCell>
                                    </TableRow>
                                ) : noTransactions ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">No pending transactions available.</TableCell>
                                    </TableRow>
                                ) : (
                                    transactions.map((row, index) => (
                                        <TableRow key={index}
                                        className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                            <TableCell>{index + 1 + (page * size)}</TableCell> {/* Adjust the serial number */}
                                            <TableCell>{row.txnid}</TableCell>
                                            <TableCell>{row.userId}</TableCell>
                                            <TableCell>{formatDate(row.addedon)}</TableCell>
                                            <TableCell>{row.netAmountDebit}</TableCell>
                                            <TableCell>{row.amount}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.settlement}</TableCell>
                                            <TableCell align='center'>
                                                <Grid style={{display:'flex', gap:'4%', justifyContent:'center'}}>
                                                    <Button
                                                        style={{textTransform:'none', backgroundColor:'green', color:'white' }}
                                                        onClick={(e) => handleOpenModal(e, row)}
                                                    >
                                                        Pay Now
                                                    </Button>
                                                    <Button
                                                        style={{textTransform:'none', backgroundColor:'#027BFE', color:'white' }}
                                                        onClick={() => handleComplete(row)}
                                                    >
                                                        Complete
                                                    </Button>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Modal open={open} onClose={handleCloseModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '2px solid #027BFE',
                    borderRadius:"18px",
                    boxShadow: 24,
                    p: 4,
                    width: 250,
                }}>
                    <Typography style={{display:"flex", justifyContent:'center', fontSize:'24px'}}>
                        Amount
                    </Typography>
                    <Typography style={{display:"flex", justifyContent:'center', marginTop:'1%', fontSize:'22px'}}>
                        ₹{selectedTransaction && selectedTransaction.amount}
                    </Typography>
                    <Typography style={{display:"flex", justifyContent:'center', marginTop:'2%', fontSize:'22px'}}>
                        User ID: {selectedTransaction && selectedTransaction.userId}
                    </Typography>
                    <Grid style={{display: 'flex', gap: '6%', justifyContent: 'center', paddingTop: '6%'}}>
                        <Button variant="contained" style={{backgroundColor: '#027BFE', color: 'white'}} onClick={handlePay}>Pay</Button>
                        <Button variant="contained" style={{backgroundColor: '#9B9B9B', color: 'white'}} onClick={handleCloseModal}>Cancel</Button>
                    </Grid>
                </Box>
            </Modal>

            <Grid container justifyContent="center" alignItems="center" style={{ marginTop: '1%', display: 'flex', justifyContent: 'center', paddingTop: '1%', gap: '3%' }}>
                <IconButton onClick={handlePrevIconClick} disabled={page === 0}>
                    <ChevronLeft />
                </IconButton>
                <TablePagination
                    component="div"
                    count={-1}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={size}
                    rowsPerPageOptions={[10]}
                    labelDisplayedRows={({ from, to, count }) => `${page + 1}`}
                />
                <IconButton onClick={handleNextIconClick} disabled={isNextDisabled}>
                    <ChevronRight />
                </IconButton>
            </Grid>
            <ToastContainer />
        </Container>
    );
};

export default DirectFTR;
