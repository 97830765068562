import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Switch,
    Typography,
    Box,
} from '@mui/material';
import axios from 'axios';

const PayinServices = () => {
    const [providerData, setProviderData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchProviderData = async () => {
        const jwtToken = localStorage.getItem('jwtToken');
        try {
            const response = await axios.get(
                'https://testapi.ipaisa.co.in/myapp/admin/get-all-gatway-provider',
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );

            console.log('API Response:', response.data); // Debug: Check response

            if (response.data.status === 200 && response.data.users) {
                setProviderData(response.data.users); // Set the full array
            } else {
                console.error('Unexpected response format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching provider data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProviderData();
    }, []);

    const handleToggle = async (providerName, currentStatus) => {
        try {
            const jwtToken = localStorage.getItem('jwtToken');
            const response = await axios.get(
                `https://testapi.ipaisa.co.in/myapp/admin/activate-gatway-provider/${providerName}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );

            if (response.data.status === 'status change successfully') {
                console.log('Provider status toggled successfully');
                await fetchProviderData(); // Re-fetch the updated provider list
            } else {
                console.error('Failed to toggle provider status:', response.data);
            }
        } catch (error) {
            console.error('Error toggling provider status:', error);
        }
    };

    return (
        <Box sx={{ padding: 3, mt: '10%' }}>
            <Typography variant="h6" gutterBottom>
                Payin Services
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Provider Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : providerData.length > 0 ? (
                            providerData.map((provider) => (
                                <TableRow key={provider.providerName}>
                                    <TableCell>{provider.providerName}</TableCell>
                                    <TableCell>{provider.status}</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={provider.status === 'ACTIVE'}
                                            onChange={() => handleToggle(provider.providerName, provider.status)}
                                            inputProps={{
                                                'aria-label': `Toggle ${provider.providerName} Status`,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    No provider data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default PayinServices;
