import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import axios from 'axios';
import { toast } from 'react-toastify';
import CommissionInvoiceReport from './CommissionInvoiceReport';
import PendingRefundReportsInvoice from './PendingRefundReportsInvoice';
import html2pdf from 'html2pdf.js';
import DownloadIcon from '@mui/icons-material/Download';
const useStyles = makeStyles(() => ({
  tableRow: {
    '& > *': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  evenRow: {
    backgroundColor: '#D0EFFF',
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  }
}));

const NewCommissionReport = () => {
  const classes = useStyles();
  const [totalCommission, setTotalCommission] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filters, setFilters] = useState({
    transactionId: '',
    payinUserId: '',
    receiverUserId: '',
    commissionAmount: '',
    commissionStatus: ''
  });
  // Function to fetch data from API
  const fetchData = async (currentPage, currentRowsPerPage) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('jwtToken'); // Retrieve the token from localStorage
      const response = await axios.get(
        `https://testapi.ipaisa.co.in/myapp/admin/getAllComisnTxn?page=${currentPage}&size=${currentRowsPerPage}`, {
        headers: {
          Authorization: `Bearer ${token}` // Use the token in the Authorization header
        }
      });

      // Check for the statusCodeValue
      if (response.data.statusCodeValue === 200) {
        if (response.data.body && Array.isArray(response.data.body)) {
          setRows(response.data.body);
          setTotalCount(response.data.body.length); // Set totalCount based on length of body
          const totalCommissionAmount = response.data.body.reduce((total, item) => total + item.commissonAmount, 0);
          setTotalCommission(Number(totalCommissionAmount).toFixed(2));
        } else {
          setRows([]);
          setTotalCount(0);
        }
      } else if (response.data.statusCodeValue === 204) {
        // Handle the case where statusCodeValue is 204 (No Content)
        toast.info(response.data.body.message || 'No Content Available');
        setRows([]);
        setTotalCount(0);
      } else {
        // Handle cases where statusCodeValue is not 200
        setError('Failed to fetch data');
        setTotalCount(0);
      }

      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  // Function to fetch commission transactions by user
  const fetchDataByUser = async (userId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('jwtToken'); // Retrieve the token from localStorage
      const response = await axios.get(
        `https://testapi.ipaisa.co.in/myapp/auth/getAllComsnTxnByUser?userId=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Use the token in the Authorization header
        },
      });

      if (response.data && Array.isArray(response.data.body)) {
        setRows(response.data.body); // Set the rows with the data from the response
        setTotalCount(response.data.body.length); // Set totalCount based on the response length
      } else {
        setRows([]);
        setTotalCount(0);
      }

      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch data when page or rowsPerPage changes
  useEffect(() => {
    const uType = localStorage.getItem('uType'); // Read uType from localStorage
    const userId = localStorage.getItem('userId'); // Read userId from localStorage (if needed)

    if (uType && uType !== 'ADMIN') {
      // If uType is not ADMIN, call fetchDataByUser
      fetchDataByUser(userId);
    } else {
      // If uType is ADMIN, call fetchData
      fetchData(page, rowsPerPage);
    }
  }, [page, rowsPerPage]);

  const handleDownloadInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.payinUserId}`);

    if (!receiptElement) {
      console.error('Receipt element not found:', `receipt-${data.payinUserId}`);
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `Payout Receipt-${data.payinUserId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4' }
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error('Error generating PDF: ', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update the page state
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to first page when rows per page changes
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <Container sx={{ padding: '2rem', marginTop: '7%' }}>
      {/* Header and Summary Sections */}
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Report</Typography>
        <PartnerArrowIcon />
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Commission Report</Typography>
      </Grid>

      <Grid sx={{ marginTop: '1%' }}>
        <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
          Commission Report
        </Typography>
      </Grid>

      <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: '3%', flexDirection: { xs: 'column', md: 'row' } }}>
        <Paper
          sx={{
            padding: { xs: '1rem', sm: '1.5rem', md: '2rem' },
            background: 'linear-gradient(135deg, #413DFF, #547AFF)',
            borderRadius: '12px',
            width: { xs: '100%', sm: '75%', md: '45%' },
            textAlign: 'center',
            display: 'flex',
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            marginBottom: { xs: '1rem', md: 0 },
            boxSizing: 'border-box',
          }}
          elevation={3}
        >
          <Grid item xs={12} md={5} sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontFamily: 'sans-serif', fontSize: '16px', color: 'white' }}>
              Total Commission
            </Typography>
            <Typography sx={{ marginTop: '1rem', fontFamily: 'sans-serif', fontSize: '20px', color: 'white' }}>
              ₹ {totalCommission}
            </Typography>
          </Grid>
        </Paper>
      </Grid>

      {/* Table Section */}
      <Grid sx={{ marginTop: '3%' }}>
        <Typography sx={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>
          History
        </Typography>

        <TableContainer
          component={Paper}
          sx={{
            marginTop: '2%',
            maxWidth: '100%',
            overflowX: 'auto',
            borderRadius: '12px 12px 0px 0px',
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: '#027BFE' }}>
              <TableRow>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Sr. No.</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Transaction Date</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Transaction Id</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Payin User ID</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Receiver User ID</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Commission Amount</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Percentage Difference</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Transaction Amount</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Commission Status</TableCell>
                <TableCell sx={{ color: 'white', textAlign: 'start' }}>Invoice</TableCell>

              </TableRow>
            </TableHead>

            <TableBody>
              {Array.isArray(rows) && rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
                  >
                    <TableCell align="start">{index + 1 + page * rowsPerPage}</TableCell>
                    <TableCell align="start">
                      {new Date(row.createATime).toLocaleDateString('en-GB')} {new Date(row.createATime).toLocaleTimeString('en-US')}
                    </TableCell>
                    <TableCell align="start">{row.transactionId}</TableCell>
                    <TableCell align="start">{row.payinUserId}</TableCell>
                    <TableCell align="start">{row.reciverUserId}</TableCell>
                    <TableCell align="start">{Number(row.commissonAmount).toFixed(2)}</TableCell>
                    <TableCell align="start">{Number(row.percentageDiff).toFixed(2)}</TableCell>
                    <TableCell align="start">{Number(row.txnAmount).toFixed(2)}</TableCell>
                    {/* <TableCell align="center">{row.statu}</TableCell> */}
                    <TableCell align="start" style={{
                      color: row.statu === 'Success' ? 'green'
                        : row.statu === 'failure' ? 'red'
                          : row.statu === 'userCancelled' ? 'red'
                            : 'inherit', // Default text color
                      fontWeight: 'bold',
                    }}>
                      {
                        row.statu === 'Success' ? 'Successful'
                          : row.statu === 'failure' ? 'Failed'
                            : row.statu === 'userCancelled' ? 'User Cancelled'
                              : row.statu === 'preinitiated' ? 'Preinitiated'
                                : row.statu || 'N/A'} {/* Default value if no match */}
                    </TableCell>
                    <TableCell align="center">
                      <DownloadIcon
                        onClick={() => handleDownloadInvoice(row)}
                        style={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                    <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                      <Grid id={`receipt-${row.payinUserId}`}>
                        <CommissionInvoiceReport data={row} />
                      </Grid>
                    </Grid>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Pagination Section */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount} // This should reflect total records fetched
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{
          disabled: totalCount < rowsPerPage, // Disable if total records are less than rows per page
        }}
      />
    </Container>
  );
};

export default NewCommissionReport;
