import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import imagePath from '../Assets/Dashboard/urban-202.gif'; // Update the path as needed

const Greeting = () => {
    // Retrieve uType from localStorage
    const uType = localStorage.getItem('uType') || 'User';
    const username = localStorage.getItem('username') || 'User';

    return (
        <Paper
            elevation={3}
            style={{
                padding: '1rem',
                backgroundColor: '#3F64E4',
                color: '#fff',
                borderRadius: '8px',
                textAlign: 'start',
            }}
        >
            <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                <Grid item>
                    <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>Hello, {username}!</Typography>
                    <Typography style={{ fontSize: '14px', fontWeight: 'lighter', paddingTop: '1%' }}>
                        Welcome, {uType}: Ready to Take Charge
                    </Typography>
                </Grid>
                <Grid item>
                    <img
                        src={imagePath}
                        alt='Urban'
                        style={{
                            maxWidth: '100px', // Set a fixed max width for the image
                            height: 'auto',    // Ensures the image scales proportionally
                            objectFit: 'cover'
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Greeting;
